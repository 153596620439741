import React from "react";
import Button from "./Button.generic";

import "./modal.style.scss";

function Modal({ children, title, show, onClose, onConfirm }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <div className="flex-end">
            <img
              src="/assets/annuler.svg"
              alt="Icône fermeture"
              onClick={onClose}
            />
          </div>
          <h3 className="modal-title">{title}</h3>
        </div>

        <div className="modal-body">{children}</div>

        <div className="modal-footer">
          <Button
            customClass={"basic_button btn-order"}
            label="Confirmer la suppression"
            type={"button"}
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
}

export default Modal;
