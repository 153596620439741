import React from "react";
import { formatDate } from "../../services/Utils.services";

function SettingsLogTableRow({ data }) {

  return (
    <tr id={data.id} >
      <td>{formatDate(new Date(data.date), "yyyy-mm-dd HH:MM:SS")}</td>
      <td>{data.message}</td>
    </tr>
  );
}

export default SettingsLogTableRow;
