import React, { useContext, useMemo, useState } from "react";
import { useEffect } from "react";
import Button from "../components/Button.generic";
import Dropdown from "../components/Dropdown.generic";
import FlatCardGame4 from "../components/FLatCardGame4.generic";
import Modal2 from "../components/Modal2.generic";
import Pagination from "../components/Pagination.generic";
import { AuthContext } from "../contexts/AuthContext";
import Layout from "../layouts/Layout";
import { getAllPlaceGroupsByCustomerId } from "../services/PlaceGroup.services";
import { getScanisetteByCustomerId } from "../services/Scanisette.services";

const games = [
  {
    id: 12,
    name: "Jeu n°1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    nb_scanisette: 5,
  },
  {
    id: 24,
    name: "Jeu n°2",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    nb_scanisette: 2,
  },
  {
    id: 36,
    name: "Jeu n°3",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    nb_scanisette: 3,
  },
  {
    id: 48,
    name: "Jeu n°4",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    nb_scanisette: 4,
  },
];

let sortByLocationOptions = [];

let sortByScanisetteOptions = [];

function Games() {
  const [selectedGames, setSelectedGames] = useState([]);
  const [dataGame, setDataGame] = useState([]);
  const [manageModal, setManageModal] = useState(false);
  const [display, setDisplay] = useState("all");
  const { user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);

  let PageSize = +6;

  const customerId = user?.customers[0]?.id;

  useEffect(() => {
    if (customerId) {
      getAllPlaceGroupsByCustomerId(customerId)
        .then(
          (resp) =>
            (sortByLocationOptions = resp.data.map((o) => {
              return {
                value: o.id,
                label: o.name,
              };
            }))
        )
        .catch((e) =>
          console.error(
            "Error while retrieving the place groups by customer id",
            e
          )
        );

      getScanisetteByCustomerId(customerId)
        .then(
          (resp) =>
            (sortByScanisetteOptions = resp.data.map((o) => {
              return {
                value: o.id,
                label: o.custom_name,
              };
            }))
        )
        .catch((e) =>
          console.error(
            "Error while retrieving the scanisettes by customer id",
            e
          )
        );
      setDataGame(games);
    }
  }, [customerId]);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return dataGame.slice(firstPageIndex, lastPageIndex);
  }, [dataGame, currentPage, PageSize]);

  const onSave = () => {
    setManageModal(false);
  };

  const handleDisplayChange = (e) => {
    setDisplay(e.target.id);
  };

  return (
    <Layout>
      <h1 className="title">JEUX</h1>
      <div className="flex-end max-80">
        <Button
          customClass={
            selectedGames.length !== 0
              ? "basic_button btn-order"
              : "basic_button btn-order disabled"
          }
          label="Gérer les jeux"
          type={"button"}
          onClick={() => setManageModal(true)}
          disabled={selectedGames.length !== 0 ? false : true}
        />
      </div>
      {currentData.map((c) => (
        <FlatCardGame4
          key={c.id}
          data={c}
          customClass="grid-5-games games-flatcardgame4"
          selectedGames={selectedGames}
          setSelectedGames={setSelectedGames}
        />
      ))}
      <Pagination
        currentPage={currentPage}
        totalCount={dataGame.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <Modal2
        title="Gérer les jeux"
        show={manageModal}
        onClose={() => setManageModal(false)}
        onConfirm={onSave}
      >
        <div className="text-center mb-6">
          <span className="bold">
            Sélectionner la/les scanisette(s) sur lesquelles vous soushaitez
            ajouter des jeux
          </span>
        </div>
        <div className="card-column">
          <label>
            <input
              type="radio"
              name="display"
              id="all"
              checked={display === "all"}
              onChange={handleDisplayChange}
            />
            Affichage sur toutes les scanisettes
          </label>
          <label>
            <input
              type="radio"
              name="display"
              id="location"
              checked={display === "location"}
              onChange={handleDisplayChange}
            />
            Affichage sur toutes les scanisettes de lieu(x)
          </label>
          {display === "location" && (
            <Dropdown
              isMulti
              onChange={(value) => console.log(value)}
              placeHolder={"Sélectionnez un ou plusieurs lieux..."}
              options={sortByLocationOptions}
            />
          )}
          <label>
            <input
              type="radio"
              name="display"
              id="selection"
              checked={display === "selection"}
              onChange={handleDisplayChange}
            />
            Affichage sur une sélection de scanisettes
          </label>
          {display === "selection" && (
            <Dropdown
              isMulti
              onChange={(value) => console.log(value)}
              placeHolder={"Sélectionnez une ou plusieurs scanisettes..."}
              options={sortByScanisetteOptions}
            />
          )}
        </div>
      </Modal2>
    </Layout>
  );
}

export default Games;
