import API from './index';

export const updateCustomerById = (id, data) => API.put(`/customer/${id}`, data);

export const getAllCustomers = () => API.get('/customer');

export const getCustomerById = (id) => API.get(`/customer/${id}`);

export const createCustomer = (data) => API.post(`/customer`, data);

export const getCustomerByScanisetteId = (id) => API.get(`/customer/scanisette/${id}`);

export const getAllCustomersGlobal = () => API.get('/global-customer/global');
