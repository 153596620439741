import React from "react";
import TableHeadItem from "./TableHead.generic";
import { v4 } from "uuid";

import "./table.style.scss";

const Table = ({
  theadData,
  tbodyData,
  customClass,
  TableRow,
  selectedRow,
  setSelectedRow,
  customerId,
  getDatas,
}) => {
  return (
    <table className={customClass}>
      <thead>
        <tr>
          {theadData.map((h) => {
            return <TableHeadItem key={v4()} item={h} />;
          })}
        </tr>
      </thead>
      <tbody>
        {tbodyData.map((item) => {
          return (
            <TableRow
              key={item.id}
              data={item}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              customerId={customerId}
              getDatas={getDatas}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
