import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import Button from "../components/Button.generic";
import ElementMaker from "../components/ElementMaker.generic";
import FlatCardAV3 from "../components/FlatCardAV3.generic";
import FlatCardScan2 from "../components/FlatCardScan2.generic";
import Modal from "../components/Modal.generic";
import TabContent from "../components/TabContent.generic";
import Table from "../components/Table.generic";
import TabNavItem from "../components/TabNavItem.generic";
import Layout from "../layouts/Layout";
import {
  getCustomerById,
  updateCustomerById,
} from "../services/Customer.services";
import { getScanisetteByCustomerId } from "../services/Scanisette.services";
import uploadFile from "../services/Upload.services";
import { state } from "../utils/func";
import ScanisettesTableRow from "./Table/ScanisettesTableRow";
import TicketTableRow from "./Table/TicketTableRow";
import { getTicketsByCustomerId, deleteTicketById } from "../services/Ticket.services";

const tableHeaderScanisette = [
  "N° série",
  "Etat",
  "Nom",
  "Lieu",
  "Licence antivirus",
  "Scans journalier",
  "Scans mensuel",
  "Virus détectés",
  "Date anniversaire",
  " ",
];

const tableHeaderTicket = [
  " ",
  "N° ticket",
  "Nom",
  "Date d'ouverture",
  "Contact",
  "Dernières MAJ",
  "Statut",
  " ",
];

function Customer() {
  const { customerId } = useParams();
  const [activeTab, setActiveTab] = useState("tab1");
  const [dataCustomer, setDataCustomer] = useState({});
  const [dataScanisette, setDataScanisette] = useState([]);
  const [dataTicket, setDataTicket] = useState([]);
  const [dataAV, setDataAV] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [showInputEle, setShowInputEle] = useState(false);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumbInner">
        <img
          src={file.preview}
          className="thumbInner-img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
          alt="file"
        />
      </div>
    </div>
  ));

  useEffect(() => {
    getScanisetteByCustomerId(customerId)
      .then((resp) => {
        const scanisettes = resp.data.map((scanisette) => {
          return {
            id: scanisette?.id,
            name: scanisette?.custom_name,
            state: state(scanisette?.heartbit_date),
            scans:
              scanisette?.scan_day.length === 0
                ? "0"
                : scanisette?.scan_day[0]?.scan,
            virus: scanisette?.nb_viruses_total,
            area: scanisette?.place_group?.name,
            scans_month:
              scanisette?.scan_month.length === 0
                ? "0"
                : scanisette?.scan_month[0]?.scan,
            anniversary: scanisette?.customer?.birth_date,
            licence: scanisette?.nb_antiviruses,
            expires: scanisette?.licence?.end_date, // Date when the scanisette licence expires
          };
        });

        setDataScanisette(scanisettes);
      })
      .catch((e) => console.error("Error while retrieving the scanisettes", e));

    getCustomerById(customerId)
      .then((resp) => {
        const data = resp.data;
        const customer = {
          name: data?.name,
          siret: data?.siret,
          address: data?.address,
          zipcode: data?.postal_code,
          city: data?.city,
          // firstname: "John",
          // lastname: "Doe",
          phone: data?.phone_number,
          email: data?.mail,
          anniversary: data.birth_date,
          logo: data?.logo || "https://via.placeholder.com/200x75",
        };
        setDataCustomer(customer);

        const antiVirus = data.antivirus.map((av) => {
          return {
            id: av?.id,
            icon: av?.antivirus?.logo || av?.antivirus?.name,
            expires: av?.end_date,
            };
        });
        setDataAV(antiVirus);
      })
      .catch((e) =>
        console.error("Error while retrieving the customer by ID", e)
      );

    if (customerId) 
      getTicketsByCustomerId(customerId)
        .then((resp) => {
          const tickets = resp.data.map((ticket) => {
            return {
              id: ticket?.id,
              status: ticket?.status,
              name: ticket?.title,
              text: ticket?.content,
              contact: ticket?.creatorEmail,
              date: ticket?.createOn,
              update: ticket?.updateOn
            };
          });
          setDataTicket(tickets);
        })
        .catch((e) =>
          console.error("Error while retrieving the tickets by customer ID", e)
        );

    // setDataCustomer(customer);
    // setDataScanisette(scanisette);
    // setDataTicket(ticket);
    // setDataAV(antiVirus);
  }, [customerId]);

  // const handleAllRenewal = (e) => {
  //   console.log(e);
  // };

  const handleNewTicket = (e) => {
    navigate ('/add/ticket', { state: { customer: customerId}});
  };

  const onDelete = () => {
    if (selectedTicket.length > 0) {
      setConfirmDeletePopup(false);
      let filtered = dataTicket;
      // Remove the selected tickets
      selectedTicket.forEach((t) => {
        for (let i = 0; i < dataTicket.length; i++) {
          if (dataTicket[i].id === +t) {
            deleteTicketById(dataTicket[i].id);
            filtered.splice(i, 1);
            // It is needed to decrement the index variable so it does not skip the next item in the array
            i--;
          }
        }
      });
      setDataTicket(filtered);
      setSelectedTicket([]);
    }
  };

  const handleSave = () => {
    setShowInputEle(!showInputEle);
    if (showInputEle) {
      if (files.length > 0) {
        const formData = new FormData();
        formData.append("file", files[0]);
        uploadFile(formData)
          .then((resp) =>
            updateCustomerById(customerId, {
              name: dataCustomer.name,
              siret: dataCustomer.siret,
              address: dataCustomer.address,
              postal_code: dataCustomer.zipcode,
              city: dataCustomer.city,
              phone_number: dataCustomer.phone,
              mail: dataCustomer.email,
              logo: `${"https://api.scanisette.k8s.demohc.io/"}/${resp.data.name}`,
            }).catch((e) =>
              console.error("Error while updating the customer", e)
            )
          )
          .catch((e) => console.error("Error while uploading a logo", e));
      } else {
        updateCustomerById(customerId, {
          name: dataCustomer.name,
          siret: dataCustomer.siret,
          address: dataCustomer.address,
          postal_code: dataCustomer.zipcode,
          city: dataCustomer.city,
          phone_number: dataCustomer.phone,
          mail: dataCustomer.email,
          // TODO: include contact name, contact firstname and anniversary
        }).catch((e) => console.error("Error while updating the customer", e));
      }
    }

    // setShowInputEle(!showInputEle);
    // if (showInputEle) {
    //   updateCustomerById(customerId, {
    //     name: dataCustomer.name,
    //     siret: dataCustomer.siret,
    //     address: dataCustomer.address,
    //     postal_code: dataCustomer.zipcode,
    //     city: dataCustomer.city,
    //     phone_number: dataCustomer.phone,
    //     mail: dataCustomer.email,
    //     // TODO: include contact name, contact firstname and anniversary
    //   }).catch((e) => console.error("Error while updating the customer", e));
    // }
  };

  return (
    <Layout>
      <h1 className="title">
        <img
          src="/assets/goback.svg"
          alt="Go back icon"
          className="go-back"
          onClick={() => navigate(-1)}
        />
        {dataCustomer.name}
      </h1>
      <ul className="nav-items">
        <TabNavItem
          title="Scanisettes"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Licences Scanisettes"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Licences Antivirus"
          id="tab3"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Tickets"
          id="tab4"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Informations"
          id="tab5"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          <Table
            theadData={tableHeaderScanisette}
            tbodyData={dataScanisette}
            customClass={"table-scanisettes"}
            TableRow={ScanisettesTableRow}
          />
        </TabContent>

        <TabContent id="tab2" activeTab={activeTab}>
          {/* <div className="flex-end max-75">
            <Button
              customClass={
                isRenewable(dataScanisette)
                  ? "basic_button btn-order"
                  : "basic_button btn-order disabled"
              }
              label="Tout renouveler"
              type={"button"}
              onClick={handleAllRenewal}
              disabled={isRenewable(dataScanisette) ? false : true}
            />
          </div> */}
          <div className="flex-column">
            {dataScanisette.map((s) => (
              <FlatCardScan2
                id={s.id}
                key={v4()}
                icon={"/assets/scanisette.svg"}
                customClass={`scanisette ${
                  s.state === "inactive"
                    ? "inactive"
                    : s.state === "active"
                    ? "active"
                    : "almost-inactive"
                }`}
                label={
                  s.state === "active"
                    ? "Active"
                    : s.state === "inactive"
                    ? "Inactive"
                    : "Inactive depuis peu"
                }
                data={s}
                onClick={(e) => console.log(e)}
              />
            ))}
          </div>
        </TabContent>

        <TabContent id="tab3" activeTab={activeTab}>
          <div className="flex-column">
            {dataAV.map((av) => (
              <FlatCardAV3
                key={v4()}
                data={av}
                customClass="grid-6 customer-flatcardav3"
              />
            ))}
          </div>
        </TabContent>

        <TabContent id="tab4" activeTab={activeTab}>
          <div className="flex-end max-85">
            <Button
              customClass={
                selectedTicket.length !== 0
                  ? "basic_button btn-order"
                  : "basic_button btn-order disabled"
              }
              label="Supprimer"
              type={"button"}
              onClick={() => setConfirmDeletePopup(true)}
              disabled={selectedTicket.length !== 0 ? false : true}
            />
            <Button
              customClass={"basic_button btn-order"}
              label="Nouveau ticket"
              type={"button"}
              onClick={handleNewTicket}
            />
          </div>
          <Table
            theadData={tableHeaderTicket}
            tbodyData={dataTicket}
            customClass={"table-scanisettes"}
            TableRow={TicketTableRow}
            selectedRow={selectedTicket}
            setSelectedRow={setSelectedTicket}
            customerId={customerId}
          />
          <Modal
            title="Supprimer"
            show={confirmDeletePopup}
            onClose={() => setConfirmDeletePopup(false)}
            onConfirm={onDelete}
          >
            Êtes-vous sûr de vouloir supprimer le(s) ticket(s) sélectionné(s) ?
          </Modal>
        </TabContent>

        <TabContent id="tab5" activeTab={activeTab}>
          <div className="max-width-100">
            <p className="bold">Client</p>

            <div className="row">
              <div className="card-column">
                <label htmlFor="customerName">Nom société</label>
                <ElementMaker
                  customClass={"input-info mb-6"}
                  type={"text"}
                  value={dataCustomer.name}
                  handleChange={(e) =>
                    setDataCustomer({ ...dataCustomer, name: e.target.value })
                  }
                  showInputEle={showInputEle}
                  id="customerName"
                  customClassSpan={"span-info mb-6"}
                />
              </div>

              <div className="card-column">
                <label htmlFor="siret">N° SIRET</label>
                <ElementMaker
                  customClass={"input-info mb-6"}
                  type={"text"}
                  value={dataCustomer.siret}
                  handleChange={(e) =>
                    setDataCustomer({ ...dataCustomer, siret: e.target.value })
                  }
                  showInputEle={showInputEle}
                  id="siret"
                  customClassSpan={"span-info mb-6"}
                />
              </div>
            </div>

            <div className="card-column">
              <label htmlFor="address">Adresse</label>
              <ElementMaker
                customClass={"input-info mb-6"}
                type={"text"}
                value={dataCustomer.address}
                handleChange={(e) =>
                  setDataCustomer({ ...dataCustomer, address: e.target.value })
                }
                showInputEle={showInputEle}
                id="address"
                customClassSpan={"span-info mb-6"}
              />
            </div>

            <div className="row">
              <div className="card-column">
                <label htmlFor="zipcode">Code postal</label>
                <ElementMaker
                  customClass={"input-info mb-6"}
                  type={"text"}
                  value={dataCustomer.zipcode}
                  handleChange={(e) =>
                    setDataCustomer({
                      ...dataCustomer,
                      zipcode: e.target.value,
                    })
                  }
                  showInputEle={showInputEle}
                  id="zipcode"
                  customClassSpan={"span-info mb-6"}
                />
              </div>

              <div className="card-column">
                <label htmlFor="city">Ville</label>
                <ElementMaker
                  customClass={"input-info mb-6"}
                  type={"text"}
                  value={dataCustomer.city}
                  handleChange={(e) =>
                    setDataCustomer({ ...dataCustomer, city: e.target.value })
                  }
                  showInputEle={showInputEle}
                  id="city"
                  customClassSpan={"span-info mb-6"}
                />
              </div>
            </div>

            <p className="bold">Contact</p>

            {/* <div className="row">
              <div className="card-column">
                <label htmlFor="lastname">Nom</label>
                <ElementMaker
                  customClass={"input-info mb-6"}
                  type={"text"}
                  value={dataCustomer.lastname}
                  handleChange={(e) =>
                    setDataCustomer({
                      ...dataCustomer,
                      lastname: e.target.value,
                    })
                  }
                  showInputEle={showInputEle}
                  id="lastname"
                  customClassSpan={"span-info mb-6"}
                />
              </div>

              <div className="card-column">
                <label htmlFor="firstname">Prénom</label>
                <ElementMaker
                  customClass={"input-info mb-6"}
                  type={"text"}
                  value={dataCustomer.firstname}
                  handleChange={(e) =>
                    setDataCustomer({
                      ...dataCustomer,
                      firstname: e.target.value,
                    })
                  }
                  showInputEle={showInputEle}
                  id="firtname"
                  customClassSpan={"span-info mb-6"}
                />
              </div>
            </div> */}

            <div className="row">
              <div className="card-column">
                <label htmlFor="phone">Téléphone</label>
                <ElementMaker
                  customClass={"input-info mb-6"}
                  type={"text"}
                  value={dataCustomer.phone}
                  handleChange={(e) =>
                    setDataCustomer({
                      ...dataCustomer,
                      phone: e.target.value,
                    })
                  }
                  showInputEle={showInputEle}
                  id="phone"
                  customClassSpan={"span-info mb-6"}
                />
              </div>

              <div className="card-column">
                <label htmlFor="email">E-mail</label>
                <ElementMaker
                  customClass={"input-info mb-6"}
                  type={"text"}
                  value={dataCustomer.email}
                  handleChange={(e) =>
                    setDataCustomer({
                      ...dataCustomer,
                      email: e.target.value,
                    })
                  }
                  showInputEle={showInputEle}
                  id="email"
                  customClassSpan={"span-info mb-6"}
                />
              </div>
            </div>

            <p className="bold">Date d'anniversaire</p>

            <div className="card-column">
              <label htmlFor="anniversary">Date d'ouverture du contrat</label>
              <span id="anniversary" className="span-info mb-6">
                {dataCustomer?.anniversary?.split("T")[0]}
              </span>
            </div>

            <p className="bold">Logo</p>
            <div className="card-column">
              {showInputEle ? (
                <div className="container">
                  <div {...getRootProps({ className: "dropzone-customer" })}>
                    <input {...getInputProps()} />
                    <img
                      src="/assets/image.svg"
                      alt="Icône"
                      className="mt-3"
                    />
                    <p className="bold">Glisser-déposer un logo</p>
                    <p>(dimension préconisée 200x75 px)</p>
                    <p className="edit" onClick={open}>
                      Parcourir
                    </p>
                  </div>
                  <div className="thumbsContainer">{thumbs}</div>
                </div>
              ) : (
                <img src={dataCustomer.logo} alt="Logo client" width={200} />
              )}
            </div>
            <div className="row-btn">
              <Button
                customClass="basic_button btn-order mb-6"
                type={"button"}
                onClick={handleSave}
                label={showInputEle ? "Enregistrer" : "Editer"}
              />
              {showInputEle ? (
                <Button
                  customClass="basic_button btn-order mb-6"
                  type={"button"}
                  onClick={() => setShowInputEle(false)}
                  label="Annuler"
                />
              ) : null}
            </div>
          </div>
        </TabContent>
      </div>
    </Layout>
  );
}

export default Customer;
