import React, { useState } from "react";
import { updateAVById } from "../services/Antivirus.services";
import Button from "./Button.generic";
import ElementMaker from "./ElementMaker.generic";
import Switch from "./Switch.generic";

function FlatCardAV5({ data, customClass, paid }) {
  const [isActive, setIsActive] = useState(false);
  const [checked, setChecked] = useState(paid);
  const [showInputEle, setShowInputEle] = useState(false);
  const [content, setContent] = useState(data.content);

  const handleSave = () => {
    setShowInputEle(!showInputEle);
    if (showInputEle) {
      updateAVById(data.id, { description: content }).catch((e) =>
        console.error("Error while updating the antivirus", e)
      );
    }
  };

  const handleToggle = () => {
    setChecked(!checked);
    updateAVById(data.id, { premium: !checked }).catch((e) =>
      console.error("Error while updating the antivirus", e)
    );
  };

  return (
    <>
      <div id={data.id} className={`flatCard ${customClass}`}>
        <div className="card_icon">
          <img src={data.icon} alt="Antivirus logo" />
        </div>
        <p className="truncated">{data.content}</p>
        <div className="container-switch">
          <Switch
            isOn={checked}
            onColor="#5f8bfa"
            handleToggle={handleToggle}
            id={`${data.name}-${data.id}`}
          />
          {checked ? "Antivirus payant" : "Antivirus gratuit"}
        </div>
        <img
          src={isActive ? "/assets/arrow-up.svg" : "/assets/arrow-down.svg"}
          alt="Up or Down arrow"
          onClick={() => setIsActive(!isActive)}
        />
      </div>
      {isActive && (
        <div className="av-content">
          <h3>{data.name}</h3>
          <p>
            <ElementMaker
              customClass={"input-content"}
              type={"textarea"}
              value={content}
              handleChange={(e) => setContent(e.target.value)}
              showInputEle={showInputEle}
              customClassSpan={"span-content"}
            />
          </p>
          <Button
            label={showInputEle ? "Enregistrer" : "Editer"}
            customClass="basic_button btn-order"
            onClick={handleSave}
          />
        </div>
      )}
    </>
  );
}

export default FlatCardAV5;
