import React, { useState } from "react";

// import Button from "./Button.generic";

import { expired, expiresSoon } from "../utils/func";

function FlatCardAV2({ data }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div className="flatCardAV2">
        <div className="card_icon">
          <img src={data.icon} alt="Icon antivirus" />
        </div>

        <div className="status">
          <div className="flex justify-content-center">
            <img
              src={
                expired(data.expires)
                  ? "/assets/check_inactive.svg"
                  : expiresSoon(data.expires) > 30
                  ? "/assets/check_active.svg"
                  : "/assets/check_almost-inactive.svg"
              }
              alt="Check"
            />
            {expired(data.expires)
              ? "Expirée"
              : expiresSoon(data.expires) > 30
              ? "A jour"
              : "Expire bientôt"}
          </div>
        </div>

        {/* <div>Expiration : {data.expires}</div> */}

        <div className="free">{data.free ? "Gratuit" : null}</div>

        {/* <Button
          customClass={
            expired(data.expires)
              ? "basic_button btn-order"
              : expiresSoon(data.expires) > 30
              ? "basic_button btn-order disabled"
              : "basic_button btn-order"
          }
          label="Commander"
          type={"button"}
          onClick={(e) => console.log(e)}
          disabled={
            expired(data.expires)
              ? false
              : expiresSoon(data.expires) > 30
              ? true
              : false
          }
        /> */}
        <img
          src={isActive ? "/assets/arrow-up.svg" : "/assets/arrow-down.svg"}
          alt="Up or Down arrow"
          onClick={() => setIsActive(!isActive)}
        />
      </div>
      {isActive && (
        <div className="accordion-content">
          <h3>{data.name}</h3>
          <p>{data.content}</p>
        </div>
      )}
    </>
  );
}

export default FlatCardAV2;
