import API from './index';

export const getAllAV = () => API.get(`/antivirus`);

export const updateAVById = (id, data) => API.put(`/antivirus/${id}`, data);

export const getCustomersByAVId = (id) => API.get(`/antivirus/customer/antivirus/${id}`);

export const getStatsAV = () => API.get(`/antivirus/statistiques/clients/licence`);

export const activateAVForScanisette = (scanisetteId, AVId) => API.get(`/antivirus/scanisette/${scanisetteId}/activate/${AVId}`);

export const desactivateAVForScanisette = (scanisetteId, AVId) => API.get(`/antivirus/scanisette/${scanisetteId}/desactivate/${AVId}`);
