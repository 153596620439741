import React from "react";
import AuthContextProvider from "./contexts/AuthContext";
import Routing from "./routes/index.routes";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <AuthContextProvider>
        <Routing />
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          newestOnTop
          closeOnClick
          rtl={false}
        />
      </AuthContextProvider>
    </>
  );
}

export default App;
