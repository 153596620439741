import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button.generic";
import Input from "../components/Input.generic";
import Pagination from "../components/Pagination.generic";
import Select from "../components/Select.generic";
import Table from "../components/Table.generic";
import Layout from "../layouts/Layout";
import { getAllCustomers } from "../services/Customer.services";
import CustomerTableRow from "./Table/CustomerTableRow";

const tableHeader = [
  "Client",
  "Nb scanisettes",
  "Date expiration",
  "Nb de scan journalier",
  "Nb de scans mensuel",
  "Nb de virus détectés",
  "Lieu",
  " ",
];

const sortByOptions = [
  {
    value: "",
    label: "Trier par",
  },
  {
    value: "name",
    label: "Nom",
  },
  {
    value: "city",
    label: "Lieu",
  },
];

function Customers() {
  const [dataCustomer, setDataCustomer] = useState([]);
  const [query, setQuery] = useState("");
  const [sortType, setSortType] = useState("name");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  let PageSize = +12;

  useEffect(() => {
    getAllCustomers()
      .then((resp) => {
        const customers = resp.data.map((customer) => {
          const dailyScans = customer.scanisette.flatMap((scan) => scan.scan_day);
          const totalDailyScans = dailyScans.reduce((total, scan) => total + parseInt(scan.scan), 0);
          const monthlyScans = customer.scanisette.flatMap((scan) => scan.scan_month);
          const totalMonthlyScans = monthlyScans.reduce((total, scan) => total + parseInt(scan.scan), 0);
          const totalViruses = customer.scanisette.reduce((acc, curr) => {
            return acc + parseInt(curr.nb_viruses_total);
          }, 0);
          return {
            id: customer?.id,
            name: customer?.name,
            nb_scanisette: customer?.scanisette?.length,
            expires: customer?.licence?.end_date,
            nb_scan: totalDailyScans,
            nb_scan_month: totalMonthlyScans,
            nb_virus: totalViruses,
            city: customer?.city,
          };
        });
        setDataCustomer(customers);
      })
      .catch((e) => console.error("Error while retrieving the customers", e));
  }, []);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(dataCustomer.length / PageSize);
    if (totalPageCount < currentPage) {
      setCurrentPage(1);
    }
    return dataCustomer
      .sort((a, b) =>
        a[sortType]?.toString().localeCompare(b[sortType]?.toString(), "fr", {
          numeric: true,
        })
      )
      .filter((row) => {
        if (query === "") {
          return row;
        } else if (row.name.toLowerCase().includes(query.toLowerCase())) {
          return row;
        } else {
          return false;
        }
      })
      .slice(firstPageIndex, lastPageIndex);
  }, [dataCustomer, currentPage, PageSize, sortType, query]);

  return (
    <Layout>
      <h1 className="title">MES CLIENTS</h1>
      <div className="flex-start max-85">
        <Input
          customClass={"search-bar mb-6"}
          type="text"
          placeholder={"Rechercher un client"}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div className="display-menu-customers">
        <Select
          name="sortBy"
          options={sortByOptions}
          handleChange={setSortType}
        />

        <div className="display-menu-right">
          <Button
            customClass={"basic_button btn-order"}
            type={"button"}
            onClick={() => navigate("/add/customer")}
            label="Ajouter un client"
          />
        </div>
      </div>
      <Table
        theadData={tableHeader}
        tbodyData={currentData}
        customClass={"table-scanisettes"}
        TableRow={CustomerTableRow}
      />
      <Pagination
        currentPage={currentPage}
        totalCount={dataCustomer.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </Layout>
  );
}

export default Customers;
