import React, { useContext, useState } from "react";

import Layout from "../layouts/Layout";
import FlatCardAV2 from "../components/FlatCardAV2.generic";
import TabNavItem from "../components/TabNavItem.generic";
import TabContent from "../components/TabContent.generic";
import FlatCardScan2 from "../components/FlatCardScan2.generic";
import { state } from "../utils/func";

import { v4 } from "uuid";
import { useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { getScanisetteByCustomerId } from "../services/Scanisette.services";
import { getCustomerById } from "../services/Customer.services";

function Subscription() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [dataScanisette, setDataScanisette] = useState([]);
  const [dataAV, setDataAV] = useState([]);
  const { user } = useContext(AuthContext);

  const customerId = user?.customers[0]?.id;

  useEffect(() => {
    if (customerId) {
      getScanisetteByCustomerId(customerId)
        .then((resp) => {
          const scanisettes = resp.data.map((s) => {
            let nb_virus = [];
            s.scans.map((s) => (nb_virus = [...nb_virus, ...s.virus]));
            return {
              id: s?.id,
              state: state(s?.heartbit_date),
              scans: s?.scans?.length,
              virus: nb_virus?.length,
              name: s?.custom_name,
              area: s?.place_group?.name,
              expires: s?.licence?.end_date, // Here the expiration date refers to the scanisette licence and not the antivirus subscription
            };
          });
          setDataScanisette(scanisettes);
        })
        .catch((e) =>
          console.error(
            "Error while retrieving the scanisette by customer ID",
            e
          )
        );
    }
    getCustomerById(customerId)
      .then((resp) => {
        const antivirus = resp.data.antivirus?.map((av) => {
          return {
            id: av?.id,
            icon: av?.antivirus?.logo,
            expires: av?.end_date,
            free: !av?.antivirus?.premium,
            name: av?.antivirus?.name,
            content: av?.antivirus?.description,
          };
        });
        setDataAV(antivirus);
      })
      .catch((e) => console.error("Error while retrieving the antivirus", e));
  }, [customerId]);

  return (
    <Layout>
      <h1 className="title">SOUSCRIPTION</h1>
      <ul className="nav-items">
        <TabNavItem
          title="Licences Scanisette"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Licences Antivirus"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          {dataScanisette.map((s) => (
            <FlatCardScan2
              id={s.id}
              key={v4()}
              icon={"/assets/scanisette.svg"}
              customClass={`scanisette ${
                s.state === "inactive"
                  ? "inactive"
                  : s.state === "active"
                  ? "active"
                  : "almost-inactive"
              }`}
              label={
                s.state === "active"
                  ? "Active"
                  : s.state === "inactive"
                  ? "Inactive"
                  : "Inactive depuis peu"
              }
              data={s}
            />
          ))}
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          {dataAV.map((av) => (
            <FlatCardAV2 key={v4()} data={av} />
          ))}
        </TabContent>
      </div>
    </Layout>
  );
}

export default Subscription;
