import React from "react";
import Button from "./Button.generic";

import "./modal2.style.scss";

function Modal2({ children, title, show, onClose, onConfirm }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-right" onClick={onClose}>
      <div className="modal-right-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-right-header">
          <div className="flex-end">
            <img
              src="/assets/annuler.svg"
              alt="Icône fermeture"
              onClick={onClose}
            />
          </div>
          <h3 className="modal-right-title">{title}</h3>
        </div>

        <div className="modal-right-body">{children}</div>

        <div className="modal-right-footer">
          <div className="modal-right-footer-container">
            <Button
              customClass={"basic_button btn-order"}
              label="Enregistrer"
              type={"button"}
              onClick={onConfirm}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal2;
