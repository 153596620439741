import React from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

import { ItemsNav } from "../utils/const";
import { isAuthorised } from "../utils/func";

import "./menu.style.scss";

function Menu({ open }) {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  return (
    <>
      <nav>
        {ItemsNav.filter((item) =>
          isAuthorised(item.allowedRoles, user?.role.name)
        ).map((item) => {
          return (
            <Link to={item.path} key={item.id}>
              <div className="link-container">
                <div className="logo-container">
                  <img src={item.icon} alt={item.title} />
                </div>
                <span className={open ? "mt-3" : "mt-3 textClosed"}>
                  {item.title}
                </span>
              </div>
            </Link>
          );
        })}
      </nav>
      <div className="bottom-menu">
        <span className="edit" onClick={() => navigate("/cgu")}>
          CGU
        </span>
        <span className="edit" onClick={() => navigate("/about")}>
          A propos
        </span>
      </div>
    </>
  );
}

export default Menu;
