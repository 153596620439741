import React from "react";
import { expired, expiresSoon } from "../utils/func";

function Card({ customClass, onClick, label, icon, id, data }) {
  return (
    <div id={id} onClick={onClick} className={`card_${customClass}`}>
      <div className="card_icon">
        <img src={icon} alt="Scanisette icon" />
      </div>

      <br />

      <div className="card_info">
        <div className="d-flex align-items-center justify-content-center g-1">
          <h3>{data.name}</h3>
          <span className="hostname">({data.hostname})</span>
        </div>
        <p>{data.area}</p>
        <span
          className={
            label === "Active"
              ? "active"
              : label === "Inactive"
              ? "inactive"
              : "almost-inactive"
          }
        >
          {label}
        </span>{" "}
        {expired(data.expires)
          ? "Licence(s) expirée(s)"
          : expiresSoon(data.expires) < 30
          ? "Licence(s) bientôt expirée(s)"
          : "Licence(s) à jour"}
        <hr />
        <p>
          Scans: <span className="info">{data.scans}</span>
        </p>
        <p className="mb-10">
          Virus détecté: <span className="info">{data.virus}</span>
        </p>
      </div>
      <div className="container__more-details">
        <span className="more-details">+ de détails</span>
      </div>
    </div>
  );
}

export default Card;
