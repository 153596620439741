import React from "react";

import "./modal4.style.scss";

function Modal3({ children, title, show, onClose }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-log" onClick={onClose}>
      <div className="modal-log-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-log-header">
          <div className="flex-end">
            <img
              src="/assets/annuler.svg"
              alt="Icône fermeture"
              onClick={onClose}
            />
          </div>
          <h3 className="modal-log-title">{title}</h3>
        </div>

        <div className="modal-log-body">{children}</div>

        
      </div>
    </div>
  );
}

export default Modal3;
