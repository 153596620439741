import React from "react";

const TableHeadItem = ({ item }) => {
  return (
    <th title={item} scope="col">
      {item}
    </th>
  );
};

export default TableHeadItem;
