import React from "react";
import { useEffect } from "react";
import { createContext, useState } from "react";
import { getCurrentUser } from "../services/User.services";

export const AuthContext = createContext(null);

function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const logout = () => {
    localStorage.removeItem("userMail");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isReloaded");
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setAccessToken(localStorage.getItem("accessToken"));
      setRefreshToken(localStorage.getItem("refreshToken"));
      getCurrentUser()
        .then((resp) => setUser(resp.data))
        .catch((e) =>
          console.error("Error while retrieving the current user", e)
        );
      // setUser(jwt_decode(localStorage.getItem("accessToken")));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
