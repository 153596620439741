import React, { useState } from "react";
import { useEffect } from "react";
import { getCustomersByAVId } from "../services/Antivirus.services";

function FlatCardAV4({ data, onClick, customClass, customClassAccordion }) {
  const [isActive, setIsActive] = useState(false);
  const [dataAccordion, setDataAccordion] = useState([]);

  useEffect(() => {
    getCustomersByAVId(data.id)
      .then((resp) => {
        const data = resp.data.map((d) => {
          return {
            id: d.id,
            icon: d.logo || "https://via.placeholder.com/150x50",
            name: d.name,
            scanisette: d.scanisette.length,
            scanisette_av: d.scanisette.filter(
              (s) => s.antivirus[0].activated === true
            ).length,
            av: d.antivirus[0].antivirus.name,
          };
        });
        setDataAccordion(data);
      })
      .catch((e) =>
        console.error("Error while retrieving the customer by antivirus Id", e)
      );
  }, []);
  const handleClickOnArrow = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div id={data.id} className={`flatCard ${customClass}`}>
        <div className="card_icon">
          <img src={data.icon} alt="Antivirus logo" />
        </div>
        <span className="bold">{data.name}</span>
        <div className="card-column">
          <span className="blue bold">{data.nb_customer}</span>
          <span>clients</span>
        </div>
        <div className="card-column">
          <span className="blue bold">{data.nb_licence}</span>
          <span>licences</span>
        </div>

        <img
          src={isActive ? "/assets/arrow-up.svg" : "/assets/arrow-down.svg"}
          alt="Up or Down arrow"
          onClick={handleClickOnArrow}
        />
      </div>
      {isActive && dataAccordion
        ? dataAccordion.map((d) => (
            <div
              key={d.id}
              id={d.id}
              className={`flatCard ${customClassAccordion}`}
              onClick={onClick}
            >
              <div className="card_icon">
                <img src={d.icon} alt="Customer logo" />
              </div>
              <span className="bold">{d.name}</span>
              <div className="card-column">
                <span className="blue bold">{d.scanisette}</span>
                <span>nb total scanisettes</span>
              </div>
              <div className="card-column">
                <span className="blue bold">{d.scanisette_av}</span>
                <span>scanisettes avec {d.av} activé</span>
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export default FlatCardAV4;
