import React from "react";
import { useState } from "react";

import { expired, expiresSoon } from "../utils/func";
import Switch from "./Switch.generic";
import {
  activateAVForScanisette,
  desactivateAVForScanisette,
} from "../services/Antivirus.services";

function FlatCardAV({ icon, id, data, active, customClass, scanisetteId }) {
  const [checked, setChecked] = useState(active);

  const activateDesactivateAV = () => {
    if (checked) {
      desactivateAVForScanisette(scanisetteId, id)
        .then(() => setChecked(!checked))
        .catch((e) =>
          console.error("Error while desactivating the antivirus", e)
        );
    } else {
      activateAVForScanisette(scanisetteId, id)
        .then(() => setChecked(!checked))
        .catch((e) =>
          console.error("Error while activating the antivirus", e)
        );
    }
  };

  return (
    <div id={id} className={`flatCard ${customClass}`}>
      <div className="card_icon">
        <img src={icon} alt="Icon antivirus" />
      </div>

      <div>
        <div className="status">
          <div className="flex">
            <img
              src={
                expired(data.expires)
                  ? "/assets/check_inactive.svg"
                  : expiresSoon(data.expires) > 30
                  ? "/assets/check_active.svg"
                  : "/assets/check_almost-inactive.svg"
              }
              alt="Check"
            />
            {expired(data.expires)
              ? "Expirée"
              : expiresSoon(data.expires) > 30
              ? "A jour"
              : "Expire bientôt"}
          </div>
          <div className="mt-2 font-size-sm">Version : {data.version}</div>
          <div className="mt-2 font-size-sm">Date de check: {data.date_check_version?.split("T")[0]}</div>
        </div>
        {/* <div>Expiration : {data.expires}</div> */}
      </div>

      <div className="container-switch">
        <Switch
          isOn={checked}
          onColor="#5f8bfa"
          handleToggle={activateDesactivateAV}
          id={`${data.name}-${data.id}`}
        />
        {checked ? "Antivirus activé" : "Antivirus désactivé"}
      </div>
      <div className="bold blue" style={{ justifySelf: "center" }}>
        {data.free ? "Gratuit" : null}
      </div>
    </div>
  );
}

export default FlatCardAV;
