import React, { useState } from "react";
import { v4 } from "uuid";
import Button from "../components/Button.generic";
import FlatCardGame2 from "../components/FlatCardGame2.generic";
import Input from "../components/Input.generic";
import TabContent from "../components/TabContent.generic";
import TabNavItem from "../components/TabNavItem.generic";
import Layout from "../layouts/Layout";

const games = [
  {
    id: 1,
    name: "Nom jeux 1",
    description: "Lorem ipsum dolore sit",
    active: true,
  },
  {
    id: 2,
    name: "Nom jeux 2",
    description: "Lorem ipsum dolore sit",
    active: true,
  },
];

function Admin() {
  const [activeTab, setActiveTab] = useState("tab2");
  const [query, setQuery] = useState("");

  const handleNewGame = (e) => {
    console.log(e);
    // TODO: create the page to add a new game
  };

  return (
    <Layout>
      <h1 className="title">GESTION</h1>
      <ul className="nav-items">
        <TabNavItem
          title="Module"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Informations"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Déploiement MAJ"
          id="tab3"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}></TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <div className="display-menu-game max-80">
            <Input
              customClass={"search-bar"}
              type="text"
              placeholder={"Rechercher un jeu"}
              onChange={(e) => setQuery(e.target.value)}
            />
            <div className="display-menu-right">
              <Button
                customClass={"basic_button btn-order"}
                label="Ajouter un jeu"
                type={"button"}
                onClick={handleNewGame}
              />
            </div>
          </div>
          <div className="flex-column">
            {games
              .filter((row) => {
                if (query === "") {
                  return row;
                } else if (
                  row.name.toLowerCase().includes(query.toLowerCase())
                ) {
                  return row;
                } else {
                  return false;
                }
              })
              .map((g) => (
                <FlatCardGame2
                  key={v4()}
                  data={g}
                  active={g.active}
                  customClass="grid-4-game max-80"
                />
              ))}
          </div>
        </TabContent>
        <TabContent id="tab3" activeTab={activeTab}></TabContent>
      </div>
    </Layout>
  );
}

export default Admin;
