import React from "react";

function Select({ name, options, handleChange }) {
  return (
    <select
      name={name}
      className={`select-menu ${name}`}
      onChange={(e) => handleChange(e.target.value)}
    >
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
}

export default Select;
