import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";

const CheckAccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    const access = localStorage.getItem("accessToken");
    if (refreshToken) {
      let decodedRefresh = jwt_decode(refreshToken);
      let decodedAccess = jwt_decode(access);
      if (
        decodedAccess.exp * 1000 < Date.now() &&
        decodedRefresh.end > Date.now()
      ) {
        axios
          .patch(
            `${"https://api.scanisette.k8s.demohc.io/"}/auth/refresh_token?refresh_token=${refreshToken}`
          )
          .then((resp) => {
            if (resp.status === 200) {
              localStorage.setItem("accessToken", resp.data.accessToken);
              localStorage.setItem("refreshToken", resp.data.refresh_token);
              localStorage.setItem("isReloaded", true);
              window.location.reload();
            } else {
              if (location.pathname !== "/") {
                navigate("/");
              }
            }
          })
          .catch((err) => console.error("Error while refreshing token", err));
      } else if (decodedAccess.exp * 1000 < Date.now()) {
        if (location.pathname !== "/") {
          navigate("/");
        }
      }
    }
    if (localStorage.getItem("isReloaded")) {
      if (location.pathname !== "/") {
        toast.success("Votre token d'accès a été renouvelé");
      }
        localStorage.removeItem("isReloaded");
    }
  }, [location.pathname]);

  return <></>;
};

export default CheckAccess;
