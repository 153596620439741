import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button.generic";
import InputWithLabel from "../components/InputWithLabel.generic";
import { getDeployedScanVirus } from "../services/Stats.services";
import { resetPassword } from "../services/User.services";
import { toast } from "react-toastify";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    getDeployedScanVirus()
      .then((resp) => {
        const dataScanisette = {
          scans: resp?.data?.scan,
          virus: resp?.data?.virus,
          scanisettes: resp?.data?.deployed,
        };
        setData(dataScanisette);
      })
      .catch((e) =>
        console.error(
          "Error while retrieving the deployed scnanisette, the number of virus detected and the number of scans realized",
          e
        )
      );
  }, []);

  const sendResetEmail = (e) => {
    if (e) e.preventDefault();
    resetPassword({email: email})
      .then((resp) => {
        toast.success("Email envoyé !");
        navigate("/");
      })
      .catch((err) => {
        console.error("Error while resetting the password", err);
      });
  };

  return (
    <div className="login">
      <div className="data-section">
        <div className="card">
          <img src="/assets/scanisette2.svg" alt="Icon usb key" />
          <span>
            <strong>{data?.scanisettes}</strong>
          </span>
          <span>scanisettes déployées</span>
        </div>

        <div className="card">
          <img src="/assets/scan.svg" alt="Icon scan" />
          <span>
            <strong>{data?.scans}</strong>
          </span>
          <span>scans réalisés</span>
        </div>

        <div className="card">
          <img src="/assets/virus.svg" alt="Icon virus" />
          <span>
            <strong>{data?.virus}</strong>
          </span>
          <span>virus détectés</span>
        </div>
      </div>
      <form className="login-section" onSubmit={sendResetEmail}>
        <img
          src={"/assets/logo.svg"}
          alt="Logo scanisette"
          className="login-logo"
        />
        <h1 className="title_form mt-12 mb-6">Mot de passe oublié</h1>
        <div className="input-container">
          <InputWithLabel
            type="email"
            label="Email"
            value={email}
            placeholder="Votre email"
            customClass="basic_input input-width"
            customClassLabel="input_label mt-8"
            onChange={(e) => setEmail(e.target.value)}
            id="email"
          />
        </div>

        <Button
          label="Envoyer l'email"
          customClass="basic_button btn-order mt-12"
          type="submit"
        />
      </form>
    </div>
  );
}

export default ForgetPassword;
