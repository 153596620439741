import React, { useState } from "react";
import Layout from "../layouts/Layout";
import { Bar } from "react-chartjs-2";
// Following import is necessary to avoid errors
import Chart from "chart.js/auto";
import Button from "../components/Button.generic";
import { useEffect } from "react";
import {
  getStatsByDate,
  getStatsPerMonth,
  getStatsPerWeek,
} from "../services/Stats.services";

// const daily = [
//   {
//     id: 1,
//     hour: "03h",
//     scan: 0,
//     virus: 0,
//   },
//   {
//     id: 2,
//     hour: "06h",
//     scan: 0,
//     virus: 0,
//   },
//   {
//     id: 3,
//     hour: "09h",
//     scan: 80,
//     virus: 8,
//   },
//   {
//     id: 4,
//     hour: "12h",
//     scan: 120,
//     virus: 30,
//   },
//   {
//     id: 5,
//     hour: "15h",
//     scan: 64,
//     virus: 12,
//   },
//   {
//     id: 6,
//     hour: "18h",
//     scan: 24,
//     virus: 4,
//   },
//   {
//     id: 7,
//     hour: "21h",
//     scan: 1,
//     virus: 0,
//   },
//   {
//     id: 8,
//     hour: "00h",
//     scan: 0,
//     virus: 0,
//   },
// ];

// const weekly = [
//   {
//     id: 1,
//     day: "Lundi",
//     scan: 50,
//     virus: 5,
//   },
//   {
//     id: 2,
//     day: "Mardi",
//     scan: 40,
//     virus: 4,
//   },
//   {
//     id: 3,
//     day: "Mercredi",
//     scan: 60,
//     virus: 6,
//   },
//   {
//     id: 4,
//     day: "Jeudi",
//     scan: 20,
//     virus: 2,
//   },
//   {
//     id: 5,
//     day: "Vendredi",
//     scan: 80,
//     virus: 8,
//   },
//   {
//     id: 6,
//     day: "Samedi",
//     scan: 30,
//     virus: 3,
//   },
//   {
//     id: 7,
//     day: "Dimanche",
//     scan: 10,
//     virus: 1,
//   },
// ];

// const monthly = [
//   {
//     id: 1,
//     week: "Semaine 1",
//     scan: 40,
//     virus: 4,
//   },
//   {
//     id: 2,
//     week: "Semaine 2",
//     scan: 60,
//     virus: 6,
//   },
//   {
//     id: 3,
//     week: "Semaine 3",
//     scan: 30,
//     virus: 3,
//   },
//   {
//     id: 4,
//     week: "Semaine 4",
//     scan: 50,
//     virus: 5,
//   },
// ];

function Stats() {
  const [period, setPeriod] = useState("daily");
  const [dailyData, setDailyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const currentDate = new Date(); // Wed Dec 28 2022 16:00:00 GMT+0100 (Central European Standard Time)
  const startDate = new Date(currentDate.getFullYear(), 0, 1); // Sat Jan 01 2022 00:00:00 GMT+0100 (Central European Standard Time)
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)); // 31 248 478 140 / 86 400 000 = 361
  const weekNumber = Math.ceil(days / 7); // 52

  useEffect(() => {
    getStatsByDate({ date: date })
      .then((resp) => {
        setDailyData(resp?.data);
      })
      .catch((e) => console.error("Error while retrieving stats by date", e));
  }, [date]);

  useEffect(() => {
    getStatsPerWeek()
      .then((resp) => {
        setWeeklyData(resp?.data);
      })
      .catch((e) =>
        console.error("Error while retrieving the stats per week", e)
      );

    getStatsPerMonth()
      .then((resp) => {
        setMonthlyData(resp?.data);
      })
      .catch((e) =>
        console.error("Error while retrieving the stats per month", e)
      );
  }, []);

  useEffect(() => {
    if (period === "daily" && dailyData) {
      setChartData({
        labels: [date],
        datasets: [
          {
            label: "scans ",
            data: [dailyData?.scan],
            backgroundColor: ["#0042EB"],
            borderWidth: 1,
            borderRadius: 500,
            barThickness: 80,
          },
          {
            label: "virus ",
            data: [dailyData?.virus],
            backgroundColor: ["#5F8BFA"],
            borderWidth: 1,
            borderRadius: 500,
            barThickness: 80,
          },
        ],
      });
    } else if (period === "weekly" && weeklyData) {
      setChartData({
        labels: [`Semaine ${weekNumber}`],
        datasets: [
          {
            label: "scans ",
            data: [weeklyData?.scan],
            backgroundColor: ["#0042EB"],
            borderWidth: 1,
            borderRadius: 500,
            barThickness: 80,
          },
          {
            label: "virus ",
            data: [weeklyData?.virus],
            backgroundColor: ["#5F8BFA"],
            borderWidth: 1,
            borderRadius: 500,
            barThickness: 80,
          },
        ],
      });
    } else if (period === "monthly" && monthlyData) {
      setChartData({
        labels: monthlyData?.map((data) => data?.month?.name),
        datasets: [
          {
            label: "scans ",
            data: monthlyData?.map((data) => data?.scan),
            backgroundColor: ["#0042EB"],
            borderWidth: 1,
            borderRadius: 500,
            barThickness: 80,
          },
          {
            label: "virus ",
            data: monthlyData?.map((data) => data?.virus),
            backgroundColor: ["#5F8BFA"],
            borderWidth: 1,
            borderRadius: 500,
            barThickness: 80,
          },
        ],
      });
    }
  }, [period, dailyData, weeklyData, monthlyData, date, weekNumber]);

  return (
    <Layout>
      <h1 className="title">STATISTIQUES</h1>
      <div className="max-width">
        <div className="btn-container mb-3">
          <input
            type="date"
            className="input-date"
            onChange={(e) => {
              setPeriod("daily");
              setDate(e.target.value);
            }}
            onFocus={() => {
              setPeriod("daily");
            }}
            value={date}
          />
          {/* <Button
            customClass={
              "basic_button btn-stats" + (period === "daily" ? " active" : "")
            }
            label="Jour"
            onClick={() => setPeriod("daily")}
          /> */}
          <Button
            customClass={
              "basic_button btn-stats" + (period === "weekly" ? " active" : "")
            }
            label="Semaine"
            onClick={() => setPeriod("weekly")}
          />
          <Button
            customClass={
              "basic_button btn-stats" + (period === "monthly" ? " active" : "")
            }
            label="Mois"
            onClick={() => setPeriod("monthly")}
          />
        </div>
        <div className="border">
          {Object.keys(chartData).length > 0 && (
            <Bar
              data={chartData}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Nombre de scans effectués et virus détectés pour l'ensemble des scanisettes",
                  },
                  legend: {
                    display: true,
                    position: "top",
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Stats;
