import React from "react";

function FlatCardCustomer({ data, onClick, customClass }) {
  return (
    <div id={data.id} onClick={onClick} className={`flatCard ${customClass}`}>
      <div className="card_icon">
        <img src={data.icon} alt="Customer logo" />
      </div>
      <span className="bold">{data.name}</span>
      <div className="card-column">
        <span className="blue bold">{data.number}</span>
        <span>scanisettes</span>
      </div>
      <div className="card-column">
        <span className="blue bold">{data.scans}</span>
        <span>scans</span>
      </div>
      <div className="card-column">
        <span className="blue bold">{data.virus}</span>
        <span>virus détectés</span>
      </div>

      <span className="more">+</span>
    </div>
  );
}

export default FlatCardCustomer;
