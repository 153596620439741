import React from "react";
import { expired, expiresSoon } from "../utils/func";

function FlatCardAV3({ data, customClass }) {
  return (
    <div id={data.id} className={`flatCard ${customClass}`}>
      <div className="card_icon">
        <img src={data.icon} alt="Antivirus logo" />
      </div>
      <div className="bold flex">
        <img
          src={
            expired(data.expires)
              ? "/assets/check_inactive.svg"
              : expiresSoon(data.expires) > 30
              ? "/assets/check_active.svg"
              : "/assets/check_almost-inactive.svg"
          }
          alt="Check"
        />
        {expired(data.expires)
          ? "Expirée"
          : expiresSoon(data.expires) > 30
          ? "A jour"
          : "Expire bientôt"}
      </div>
      <span>Expiration : {data.expires}</span>
    </div>
  );
}

export default FlatCardAV3;
