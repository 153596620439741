import React from "react";

function Link({ label, onClick, customClass }) {
  return (
    <label className={customClass} onClick={onClick}>
      {label}
    </label>
  );
}

export default Link;
