import API from './index';

export const login = (creds) => API.post('/auth/login', creds);

export const getAllUsers = () => API.get('/auth');

export const updateUserById = (id, data) => API.put(`/auth/${id}`, data);

export const deleteMultipleUsers = (ids) => API.post(`/auth/delete_multiple`, ids);

export const getUsersByCustomerId = (id) => API.get(`/auth/customer/${id}`);

export const createUser = (data) => API.post(`/auth`, data);

export const getCurrentUser = () => API.get(`/auth/user/current`);

export const resetPassword = (data) => API.post(`/auth/reset_password`, data);

export const changePassword = (data) => API.post(`/auth/change_password`, data);