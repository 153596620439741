import React, { useState } from "react";
import Button from "./Button.generic";
import ElementMaker from "./ElementMaker.generic";
import Switch from "./Switch.generic";

function FlatCardGame2({ data, active, customClass }) {
  const [checked, setChecked] = useState(active);
  const [isActive, setIsActive] = useState(false);
  const [description, setDescription] = useState(data.description);
  const [showInputEle, setShowInputEle] = useState(false);

  return (
    <>
      <div id={data.id} className={`flatCard ${customClass}`}>
        <div className="card_icon">
          <div className="icon"></div>
        </div>

        <div>
          <div className="flat-card-name">{data.name}</div>
          <div>{data.description}</div>
        </div>

        <div className="container-switch">
          <Switch
            isOn={checked}
            onColor="#5f8bfa"
            handleToggle={() => setChecked(!checked)}
            id={`${data.name}-${data.id}`}
          />
          {checked
            ? "Jeu actif dans le catalogue"
            : "Jeu désactivé du catalogue"}
        </div>
        <img
          src={isActive ? "/assets/arrow-up.svg" : "/assets/arrow-down.svg"}
          alt="Up or Down arrow"
          onClick={() => setIsActive(!isActive)}
        />
      </div>
      {isActive && (
        <div className=" accordion-content accordion-admin-game">
          <h3>{data.name}</h3>
          <p>
            <ElementMaker
              customClass={"input-content"}
              type={"textarea"}
              value={description}
              handleChange={(e) => setDescription(e.target.value)}
              showInputEle={showInputEle}
              customClassSpan={"span-content"}
            />
          </p>
          <Button
            label={showInputEle ? "Enregistrer" : "Editer"}
            customClass="basic_button btn-order"
            onClick={() => setShowInputEle(!showInputEle)}
          />
        </div>
      )}
    </>
  );
}

export default FlatCardGame2;
