import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import Button from "./Button.generic";
import { getCurrentUser } from "../services/User.services";

function Header() {
  const { logout, user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [accountLogout, setAccountLogout] = useState(false);

  const { firstname } = user || "";

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      getCurrentUser().then((resp) => setUser(resp.data))
      .catch((e) =>
        console.error("Error while retrieving the current user", e)
      );
      // setUser(jwt_decode(localStorage.getItem("accessToken")));
    }
  }, [setUser]);

  return (
    <header className="header">
      <Link to="/dashboard">
        <img
          src={"/assets/logo.svg"}
          alt="Logo scanisette"
          className="header_logo"
        />
      </Link>

      <div className="header_avatar">
        <span>
          Bonjour <span className="bold">{firstname}</span>
        </span>
        <img
          src={"/assets/profil.svg"}
          alt="Avatar"
          onClick={() => setAccountLogout(!accountLogout)}
        />
      </div>

      {accountLogout && (
        <div className="accountLogout">
          <Button
            customClass={"basic_button"}
            label="Se déconnecter"
            type={"button"}
            onClick={() => handleLogout()}
          ></Button>
        </div>
      )}
    </header>
  );
}

export default Header;
