import React, { useState } from "react";
import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "../components/Button.generic";
import Input from "../components/Input.generic";
import Modal from "../components/Modal.generic";
import Pagination from "../components/Pagination.generic";
import Table from "../components/Table.generic";
import Layout from "../layouts/Layout";
import { deleteMultipleUsers, getAllUsers } from "../services/User.services";
import UserTableRow from "./Table/UserTableRow";

const tableHeaderUser = [
  " ",
  "Société",
  "Nom",
  "Prénom",
  "E-mail",
  "Droits",
  " ",
];

function Users() {
  const [dataUser, setDataUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  // const navigate = useNavigate();

  let PageSize = +12;

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    getAllUsers()
      .then((resp) => {
        const users = resp.data.map((user) => {
          return {
            id: user?.id,
            customer_name: user?.customers[0]?.name,
            lastname: user?.name,
            firstname: user?.firstname,
            email: user?.email,
            role: user?.role?.name,
            customer_id: user?.customers[0]?.id,
            role_weight: user?.role?.weight,
            role_id: user?.role?.id,
          };
        });
        setDataUser(users);
      })
      .catch((e) => console.error("Error while retrieving the users", e));
  };

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    // The following lines are to prevent the following behavior : if the user is on page 2 and start to search for a user the results won't be shown as they are displayed on page 1 but the current page is 2
    const totalPageCount = Math.ceil(
      dataUser.filter((row) => {
        if (query === "") {
          return row;
        } else if (row.lastname.toLowerCase().includes(query.toLowerCase())) {
          return row;
        } else {
          return false;
        }
      }).length / PageSize
    );
    if (totalPageCount < currentPage) {
      setCurrentPage(1);
    }
    // End
    setCurrentData(
      dataUser
        .filter((row) => {
          if (query === "") {
            return row;
          } else if (row.lastname.toLowerCase().includes(query.toLowerCase())) {
            return row;
          } else {
            return false;
          }
        })
        .slice(firstPageIndex, lastPageIndex)
    );
  }, [dataUser, currentPage, PageSize, query]);

  // const handleNewUser = () => {
  //   navigate("/add/user");
  // };

  const onDelete = () => {
    if (selectedUser.length > 0) {
      deleteMultipleUsers({ object_ids: selectedUser.map((s) => +s) })
        .then(() => {
          getDatas();
          setConfirmDeletePopup(false);
          setSelectedUser([]);
        })
        .catch((e) => console.error("Error while deleting multiple users", e));
      // setConfirmDeletePopup(false);
      // let filtered = dataUser;
      // // Remove the selected users
      // selectedUser.forEach((t) => {
      //   for (let i = 0; i < dataUser.length; i++) {
      //     if (dataUser[i].id === +t) {
      //       filtered.splice(i, 1);
      //       // It is needed to decrement the index variable so it does not skip the next item in the array
      //       i--;
      //     }
      //   }
      // });
      // setDataUser(filtered);
      // // Even if currentData shall be re-computed when dataUser is modified (see above useEffect), one has to set currentData to filtered to trigger the render of the table when users are deleted
      // setCurrentData(filtered);

      // TODO: delete the users from the database
    }
  };

  return (
    <Layout>
      <h1 className="title">UTILISATEURS</h1>
      <div className="display-menu-customers">
        <Input
          customClass={"search-bar"}
          type="text"
          placeholder={"Rechercher un nom"}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="display-menu-right">
          <Button
            customClass={
              selectedUser.length !== 0
                ? "basic_button btn-order"
                : "basic_button btn-order disabled"
            }
            label="Supprimer un utilisateur"
            type={"button"}
            onClick={() => setConfirmDeletePopup(true)}
            disabled={selectedUser.length !== 0 ? false : true}
          />
          {/* <Button
            customClass={"basic_button btn-order"}
            label="Ajouter un utilisateur"
            type={"button"}
            onClick={handleNewUser}
          /> */}
        </div>
      </div>
      <Table
        theadData={tableHeaderUser}
        tbodyData={currentData}
        customClass={"table-scanisettes"}
        TableRow={UserTableRow}
        selectedRow={selectedUser}
        setSelectedRow={setSelectedUser}
        getDatas={getDatas}
      />
      <Pagination
        currentPage={currentPage}
        totalCount={
          dataUser.filter((row) => {
            if (query === "") {
              return row;
            } else if (
              row.lastname.toLowerCase().includes(query.toLowerCase())
            ) {
              return row;
            } else {
              return false;
            }
          }).length
        }
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <Modal
        title="Supprimer"
        show={confirmDeletePopup}
        onClose={() => setConfirmDeletePopup(false)}
        onConfirm={onDelete}
      >
        Êtes-vous sûr de vouloir supprimer le(s) utilisateur(s) sélectionné(s) ?
      </Modal>
    </Layout>
  );
}

export default Users;
