import axios from 'axios';
// import { getCookie } from './Utils.services';

const instance = axios.create({
  baseURL: "https://api.scanisette.k8s.demohc.io/",
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

instance.interceptors.request.use(
  (config) => {
    // const token = getCookie("accessToken");
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const uploadFile = (data) => instance.post(`/upload/single`, data);

export default uploadFile;