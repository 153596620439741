import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Switch from "./Switch.generic";

export function SortableItem({ id }) {
  const [checked, setChecked] = useState(true);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="flatCard grid-3" ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img src="/assets/move.svg" alt="Icône déplacement" /> 
      <span>{id.split('-')[1]}</span> 
      {/* As the id is in the form of "campaignId-campaignName", we display just the campaign name */}
      <div className="container-switch">
        <Switch
          isOn={checked}
          onColor="#5f8bfa"
          handleToggle={() => setChecked(!checked)}
          id={id}
        />
        {checked ? "Com. activée" : "Com. désactivée"}
      </div>
    </div>
  );
}
