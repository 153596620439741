import React from "react";
import { formatDate } from "../../services/Utils.services";
import './ScanisetteLogScanTableRow.scss';

function ScanisetteLogScanTableRow({ data }) {
  return (
    <tr>
      {/* <td>{data.model}</td>
      <td>{data.volume_name}</td>
      <td>{data.size}</td>
      <td>{data.brand}</td>
      <td>{data.serial_key}</td>
      <td>{data.antivirus}</td>
      <td>{formatDate(new Date(data.date), "yyyy-mm-dd HH:MM:SS")}</td>
      <td>{data.nb_files}</td>
      <td>
        {data.virus.length > 0 ?data.virus.map((v, index) => (
          <div key={index} className="virus_detail mt-1 mb-1">
            {v.name}
          </div>
        )): <div className="no_virus">Aucun virus détecté</div>}
      </td>
      <td>
        {data.virus.length > 0 ? data.virus.map((v, index) => (
          <div key={index} className="virus_detail mt-1 mb-1">
            {v.file}
          </div>
        )) : <div className="no_virus">Aucun virus détecté</div>}
      </td> */}
      <td>{formatDate(new Date(data.date), "yyyy-mm-dd HH:MM:SS")}</td>
      <td>{data.message}</td>
    </tr>
  );
}

export default ScanisetteLogScanTableRow;
