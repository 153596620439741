import React from "react";
import Input from "./Input.generic";

function ElementMaker({
  value,
  handleChange,
  showInputEle,
  customClass,
  customClassSpan,
  type,
  id,
}) {
  return (
    <span>
      {showInputEle ? (
        type === "textarea" ? (
          <textarea className={customClass} onChange={handleChange} id={id}>
            {value}
          </textarea>
        ) : (
          <Input
            customClass={customClass}
            type={type}
            value={value}
            onChange={handleChange}
            id={id}
          />
        )
      ) : (
        <span id={id} className={customClassSpan}>
          {value}
        </span>
      )}
    </span>
  );
}

export default ElementMaker;
