import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FlatCardAV4 from "../components/FlatCardAV4.generic";
import FlatCardAV5 from "../components/FlatCardAV5.generic";
import TabContent from "../components/TabContent.generic";
import TabNavItem from "../components/TabNavItem.generic";
import Layout from "../layouts/Layout";
import { getAllAV, getStatsAV } from "../services/Antivirus.services";

function AntiVirus() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [dataAV, setDataAV] = useState([]);
  const [statsAV, setStatsAV] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getStatsAV()
      .then((resp) => setStatsAV(resp.data))
      .catch((e) =>
        console.error("Error while retrieving the stats about the antivirus", e)
      );
  }, []);

  useEffect(() => {
    getAllAV()
      .then((resp) => {
        const antiVirus = resp.data.map((av) => {
          return {
            id: av?.id,
            icon: av?.logo || "https://via.placeholder.com/150x50",
            nb_customer: statsAV.filter((s) => s.id === av.id)[0]?.customer,
            nb_licence: statsAV.filter((s) => s.id === av.id)[0]?.nb_licence,
            paid: av?.premium,
            content: av?.description,
            name: av?.name,
          };
        });
        setDataAV(antiVirus);
      })
      .catch((e) => console.error("Error while retrieving the antivirus", e));
  }, [statsAV]);

  const handleClick = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  return (
    <Layout>
      <h1 className="title">MES ANTIVIRUS</h1>
      <ul className="nav-items">
        <TabNavItem
          title="Licences"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Informations"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          <div className="flex-column">
            {dataAV.map((d) => (
              <FlatCardAV4
                key={d.id}
                data={d}
                customClass="grid-5 antivirus-flatcardav4"
                customClassAccordion="grid-4-flatcardav4-accordion"
                onClick={(e) => handleClick(e.currentTarget.id)}
              />
            ))}
          </div>
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <div className="flex-column">
            {dataAV.map((d) => (
              <FlatCardAV5
                key={d.id}
                data={d}
                customClass="grid-4-content"
                paid={d.paid}
                setData={setDataAV}
              />
            ))}
          </div>
        </TabContent>
      </div>
    </Layout>
  );
}

export default AntiVirus;
