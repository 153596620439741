import React, { useState } from "react";

function FlatCardGame4({ data, customClass, selectedGames, setSelectedGames }) {
  const [isActive, setIsActive] = useState(false);

  const handleCheckboxChange = (e) => {
    // If the selected row are not yet in the array add it, else remove it from the array
    let newArray = [...selectedGames, e.target.value];
    if (selectedGames.includes(e.target.value)) {
      newArray = newArray.filter((d) => d !== e.target.value);
    }
    setSelectedGames(newArray);
  };

  return (
    <>
      <div id={data.id} className={`flatCard ${customClass}`}>
        <input
          type="checkbox"
          id={data.id}
          value={`${data.id}-${data.name}`}
          onChange={handleCheckboxChange}
        />

        <div className="card_icon">
          <div className="icon"></div>
        </div>

        <div className="card-column">
          <span className="bold">{data.name}</span>
          <span className="truncated">Description : {data.description}</span>
        </div>

        <div className="card-column">
          <span>
            Actif sur <span className="blue bold">{data.nb_scanisette}</span>
          </span>
          <span>scanisettes</span>
        </div>
        <img
          src={isActive ? "/assets/arrow-up.svg" : "/assets/arrow-down.svg"}
          alt="Up or Down arrow"
          onClick={() => setIsActive(!isActive)}
        />
      </div>
      {isActive && (
        <div className="accordion-games">
          <h3>{data.name}</h3>
          <p>{data.description}</p>
        </div>
      )}
    </>
  );
}

export default FlatCardGame4;
