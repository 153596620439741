import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button.generic";
import InputWithLabel from "../components/InputWithLabel.generic";
import Select from "../components/Select.generic";
import { AuthContext } from "../contexts/AuthContext";
import Layout from "../layouts/Layout";
import { getAllCustomers } from "../services/Customer.services";
import { getAllRoles } from "../services/Role.services";
import { createUser } from "../services/User.services";

function CreateUser() {
  const [userData, setUserData] = useState({
    lastname: "",
    firstname: "",
    email: "",
  });
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [role, setRole] = useState(user?.role.weight);
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([
    {
      value: "",
      label: "Sélectionner un rôle",
    },
  ]);
  const [customers, setCustomers] = useState([
    {
      value: "",
      label: "Sélectionner un client",
    },
  ]);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const customerId = user?.customers[0]?.id;

  useEffect(() => {
    if (user) {
      setRole(user?.role.weight);
    }
  }, [user]);

  useEffect(() => {
    if (role) {
      getAllRoles()
        .then((resp) => {
          if (role <= 100) {
            let rolesArr = [
              ...roles,
              ...resp.data.map((r) => {
                return {
                  value: r.weight,
                  label: r.name,
                };
              }),
            ];
            setRoles(rolesArr);
          } else if (role >= 101) {
            let rolesArr = [
              ...roles,
              ...resp.data
                .filter((r) => r.weight >= 101)
                .map((r) => {
                  return {
                    value: r.weight,
                    label: r.name,
                  };
                }),
            ];
            setRoles(rolesArr);
          }
        })
        .catch((e) => console.error("Error while retrieving the roles", e));
    }
    getAllCustomers()
      .then((resp) => {
        let customersArr = [
          ...customers,
          ...resp.data.map((r) => {
            return {
              value: r.id,
              label: r.name,
            };
          }),
        ];
        setCustomers(customersArr);
      })
      .catch((e) => console.error("Error while retrieving the customers", e));
  }, [role]);

  const handleSave = () => {
    if (role >= 101) {
      // If role is Admin or with less privileges than Admin attach the current customer ID to the user
      createUser({
        email: userData.email,
        name: userData.lastname,
        firstname: userData.firstname,
        customer: [{ id: customerId }],
        role: { weight: selectedRole },
      })
        .then(() => navigate("/settings"))
        .catch((e) => console.error("Error while creating a user", e));
    } else if (role <= 100) {
      // If role is Super Admin or with less privileges than Super Admin attach the selected customer if selected
      if (selectedCustomer !== "") {
        createUser({
          email: userData.email,
          name: userData.lastname,
          firstname: userData.firstname,
          customer: [{ id: selectedCustomer }],
          role: { weight: selectedRole },
        })
          .then(() => navigate("/users"))
          .catch((e) => console.error("Error while creating a user", e));
      } else {
        createUser({
          email: userData.email,
          name: userData.lastname,
          firstname: userData.firstname,
          role: { weight: selectedRole },
        })
          .then(() => navigate("/users"))
          .catch((e) => console.error("Error while creating a user", e));
      }
    }
  };

  const handleCancel = () => {
    if (role >= 101) {
      navigate("/settings");
    } else {
      navigate("/users");
    }
  };

  return (
    <Layout>
      <h1 className="title">Créer un utilisateur</h1>
      <div className="max-width-100">
        <div className="row">
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="Nom"
              value={userData?.lastname}
              placeholder="Nom"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setUserData({ ...userData, lastname: e.target.value })
              }
              id="lastname"
            />
          </div>
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="Prénom"
              value={userData?.firstname}
              placeholder="Prénom"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setUserData({ ...userData, firstname: e.target.value })
              }
              id="lastname"
            />
          </div>
        </div>

        <div className="row">
          <div className="card-column">
            <InputWithLabel
              type="email"
              label="E-mail"
              value={userData?.email}
              placeholder="E-mail"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
              id="email"
            />
          </div>
          <div className="card-column mt-3 width-50ch">
            <span>Rôle</span>
            <Select
              name="roles"
              options={roles}
              handleChange={setSelectedRole}
            />
          </div>
        </div>
        {role <= 100 ? (
          <div className="card-column mt-3 width-50ch">
            <span>Client</span>
            <Select
              name="customers"
              options={customers}
              handleChange={setSelectedCustomer}
            />
          </div>
        ) : null}

        <div className="row-btn">
          <Button
            label="Enregistrer"
            customClass="basic_button btn-order mt-8 mb-12"
            type="button"
            onClick={handleSave}
          />
          <Button
            label="Annuler"
            customClass="basic_button btn-order mt-8 mb-12"
            type="button"
            onClick={handleCancel}
          />
        </div>
      </div>
    </Layout>
  );
}

export default CreateUser;
