import React from "react";

import { expired, expiresSoon } from "../utils/func";

function FlatCardAV6({ icon, id, data, active, customClass }) {
  return (
    <div id={id} className={`flatCard ${customClass}`}>
      <div className="card_icon">
        <img src={icon} alt="Icon antivirus" />
      </div>

      <div>
        <div className="status">
          <div className="flex">
            <img
              src={
                expired(data.expires)
                  ? "/assets/check_inactive.svg"
                  : expiresSoon(data.expires) > 30
                  ? "/assets/check_active.svg"
                  : "/assets/check_almost-inactive.svg"
              }
              alt="Check"
            />
            {expired(data.expires)
              ? "Expirée"
              : expiresSoon(data.expires) > 30
              ? "A jour"
              : "Expire bientôt"}
          </div>
          <div className="mt-2 font-size-sm">Expiration : {data.expires}</div>
          <div className="mt-2 font-size-sm">Version : {data.version}</div>
          <div className="mt-2 font-size-sm">Date de check: {data.date_check_version?.split("T")[0]}</div>
        </div>
        
      </div>

      <div className="antivirus bold flex">
        <img
          src={
            active ? "/assets/check_active.svg" : "/assets/check_inactive.svg"
          }
          alt="Check"
        />
        {active ? "Antivirus activé" : "Antivirus désactivé"}
      </div>
      <div className="bold blue" style={{ justifySelf: "center" }}>
        {data.free ? "Gratuit" : null}
      </div>
    </div>
  );
}

export default FlatCardAV6;
