import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import InputWithLabel from "../components/InputWithLabel.generic";
import TabContent from "../components/TabContent.generic";
import TabNavItem from "../components/TabNavItem.generic";
import Layout from "../layouts/Layout";
import { useDropzone } from "react-dropzone";
import Button from "../components/Button.generic";

const campaign = {
  name: "Nom campagne",
  image: "",
};

function Campaign() {
  const { campaignId } = useParams();
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState("tab1");
  const [name, setName] = useState(data?.name);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const otherFiles = acceptedFiles.map((file) => (
    <p key={file.path}>
      {file.path} - {file.size} bytes
    </p>
  ));

  useEffect(() => {
    setData(campaign);
  }, []);

  return (
    <Layout>
      <h1 className="title">
        <Link to={`/communication`}>
          <img
            src="/assets/goback.svg"
            alt="Go back icon"
            className="go-back"
          />
        </Link>{" "}
        {data.name}
      </h1>
      <ul className="nav-items">
        <TabNavItem
          title="Contenu"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Aperçu"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          <div className="card-column max-70">
            <InputWithLabel
              type="text"
              label="Nom de la campagne"
              value={data?.name}
              placeholder="Nom de  la campagne..."
              customClass="basic_input mb-6"
              customClassLabel="input_label"
              onChange={(e) => setName(e.target.value)}
              id="campaign_name"
            />

            <span>Image</span>
            <div className="container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <img src="/assets/image.svg" alt="Icône image" />
                <p className="bold">Glisser-déposer une image</p>
                <p>(jpeg, png, gif ; dimension préconisée 2670x1545 px)</p>
                <p className="edit" onClick={open}>
                  Parcourir
                </p>
                {otherFiles}
              </div>
            </div>
            <div style={{ marginRight: "auto", marginLeft: "auto" }}>
              <Button
                customClass={"basic_button btn-order mt-5"}
                label="Enregistrer"
                type={"button"}
                onClick={(e) => console.log(e)}
              />
            </div>
          </div>
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <div className="flex-column max-70">
            <div class="device device-surface-pro-2017">
              <div class="device-frame">
                <img class="device-screen" src={files[0]?.preview} />
              </div>
              <div class="device-stripe"></div>
              <div class="device-header"></div>
              <div class="device-sensors"></div>
              <div class="device-btns"></div>
              <div class="device-power"></div>
              <div className="device-home"></div>
            </div>
          </div>
        </TabContent>
      </div>
    </Layout>
  );
}

export default Campaign;
