import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button.generic";
import InputWithLabel from "../components/InputWithLabel.generic";
import { getDeployedScanVirus } from "../services/Stats.services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { changePassword } from "../services/User.services";
import { toast } from "react-toastify";

const schema = yup
  .object({
    password: yup
      .string()
      .required("Ce champ est obigatoire")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$/,
        "Doit contenir entre 6 et 20 caractères, une letre, un chriffre, et un caractère spécial"
      ),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passe doivent être identiques"
      ),
  })
  .required();

function ResetPassword() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  let token = query.get('token')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getDeployedScanVirus()
      .then((resp) => {
        const dataScanisette = {
          scans: resp?.data?.scan,
          virus: resp?.data?.virus,
          scanisettes: resp?.data?.deployed,
        };
        setData(dataScanisette);
      })
      .catch((e) =>
        console.error(
          "Error while retrieving the deployed scnanisette, the number of virus detected and the number of scans realized",
          e
        )
      );
  }, []);

  const savePassword = (formData) => {
    changePassword({ token: token, password: formData.password })
      .then((resp) => {
        toast.success("Mot de passe sauvegardé !");
        navigate("/");
      })
      .catch((err) => {
        console.error("Error while changing the password", err);
      });
  };

  return (
    <div className="login">
      <div className="data-section">
        <div className="card">
          <img src="/assets/scanisette2.svg" alt="Icon usb key" />
          <span>
            <strong>{data?.scanisettes}</strong>
          </span>
          <span>scanisettes déployées</span>
        </div>

        <div className="card">
          <img src="/assets/scan.svg" alt="Icon scan" />
          <span>
            <strong>{data?.scans}</strong>
          </span>
          <span>scans réalisés</span>
        </div>

        <div className="card">
          <img src="/assets/virus.svg" alt="Icon virus" />
          <span>
            <strong>{data?.virus}</strong>
          </span>
          <span>virus détectés</span>
        </div>
      </div>
      <form className="login-section" onSubmit={handleSubmit(savePassword)}>
        <img
          src={"/assets/logo.svg"}
          alt="Logo scanisette"
          className="login-logo"
        />
        <h1 className="title_form mt-12 mb-6">
          Définissez un nouveau mot de passe
        </h1>
        <div className="input-container">
          <InputWithLabel
            type="password"
            label="Nouveau mot de passe"
            placeholder=""
            customClass="basic_input input-width"
            customClassLabel="input_label mt-8"
            id="password"
            register={register}
          />
          {errors.password && (
            <span role="alert">{errors.password?.message}</span>
          )}
        </div>

        <div className="input-container">
          <InputWithLabel
            type="password"
            label="Confirmation du mot de passe"
            placeholder=""
            customClass="basic_input input-width"
            customClassLabel="input_label mt-8"
            id="passwordConfirmation"
            register={register}
          />
          {errors.passwordConfirmation && (
            <span role="alert">{errors.passwordConfirmation?.message}</span>
          )}
        </div>

        <Button
          label="Sauvegarder"
          customClass="basic_button btn-order mt-12"
          type="submit"
        />
      </form>
    </div>
  );
}

export default ResetPassword;
