import React, { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../layouts/Layout";
import FlatCardAV from "../components/FlatCardAV.generic";
// import FlatCardCom from "../components/FlatCardCom.generic";
// import FlatCardGame from "../components/FlatCardGame.generic";

import { v4 } from "uuid";
import { expired, expiresSoon, state } from "../utils/func";
import { useEffect } from "react";
import {
  getScanisetteById,
  updateScanisette,
} from "../services/Scanisette.services";
import { AuthContext } from "../contexts/AuthContext";
import FlatCardAV6 from "../components/FlatCardAV6.generic";
// import FlatCardCom2 from "../components/FlatCardCom2.generic";
// import FlatCardGame3 from "../components/FlatCardGame3.generic";
import { getCustomerByScanisetteId } from "../services/Customer.services";
import Button from "../components/Button.generic";
import Modal3 from "../components/Modal3.generic";
import Modal4 from "../components/Modal4.generic";
import Input from "../components/Input.generic";
import {
  createPlaceGroup,
  getAllPlaceGroupsByCustomerId,
  updatePlaceGroupById,
} from "../services/PlaceGroup.services";
import uploadFile from "../services/Upload.services";
import { formatDate } from "../services/Utils.services";
import Table from "../components/Table.generic";
import ScanisetteLogScanTableRow from "./Table/ScanisetteLogScanTableRow";
import Pagination from "../components/Pagination.generic";
import { getLogByScanisetteId } from "../services/Log.services";

// const communication = [
//   {
//     id: 1,
//     begin: "2022-11-01",
//     end: "2023-01-31",
//     name: "Nom campagne 1",
//     active: true,
//   },
//   {
//     id: 2,
//     begin: "2022-11-01",
//     end: "2023-01-31",
//     name: "Nom campagne 2",
//     active: true,
//   },
// ];

// const games = [
//   {
//     id: 1,
//     name: "Nom jeux 1",
//     description: "Lorem ipsum dolore sit",
//     active: true,
//   },
//   {
//     id: 2,
//     name: "Nom jeux 2",
//     description: "Lorem ipsum dolore sit",
//     active: true,
//   },
// ];

function Scanisette() {
  const { scanisetteId } = useParams();
  const [dataScanisette, setDataScanisette] = useState({});
  const [scanisette, setScanisette] = useState(null);
  const [dataAntiVirus, setDataAntiVirus] = useState([]);
  // const [dataCom, setDataCom] = useState([]);
  // const [dataGame, setDataGame] = useState([]);
  const { user } = useContext(AuthContext);
  const [role, setRole] = useState(user?.role?.name);
  const [customerId, setCustomerId] = useState("");
  const [manageModal, setManageModal] = useState(false);
  const [scanissetteName, setScanissetteName] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [scanisetteLocationSelect, setScanisetteLocationSelect] = useState("");
  const [scanisetteLocationInput, setScanisetteLocationInput] = useState("");
  const [imageScan, setImageScan] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [compteurPage, setCompteurPage] = useState(1);
  const navigate = useNavigate();
  const [openLogModal, setOpenLogModal] = useState(false);
  const [currentPageLog, setCurrentPageLog] = useState(1);
  const [dataLog, setDataLog] = useState([]);

  let PageSize = +10;

  const tableHeaderLogs = ["Date", "Message"];

  useEffect(() => {
    if (user) {
      setRole(user?.role?.name);
    }
  }, [user]);

  useEffect(() => {
    if (imageScan) {
      setPreviewImage(URL.createObjectURL(imageScan));
    }
  }, [imageScan]);

  const getData = () => {
    getScanisetteById(scanisetteId)
      .then((resp) => {
        let nb_virus = [];
        setScanisette(resp.data);
        console.log(resp.data);
        resp.data.scans.map((s) => (nb_virus = [...nb_virus, ...s.virus]));
        const scanisette = {
          id: resp?.data?.id,
          state: state(resp?.data?.heartbit_date),
          scans: resp?.data?.scans?.length,
          virus: nb_virus?.length,
          name: resp?.data?.custom_name,
          area: resp?.data?.place_group?.name,
          expires: resp?.data?.licence?.end_date,
          anniversary: resp?.data?.customer?.birth_date,
          hostname: resp?.data?.hostname,
          uri_image_no_comm: resp?.data?.uri_image_no_comm,
          manufacturer: resp?.data?.manufacturer,
          serial: resp?.data?.serial,
          model: resp?.data?.model,
          public_ip: resp?.data?.public_ip,
          local_ip: resp?.data?.local_ip,
        };
        setDataScanisette(scanisette);

        const antiVirus = resp.data.antivirus.map((av) => {
          return {
            id: av?.id,
            icon: av?.antivirus?.logo || av?.antivirus?.name,
            expires: av?.end_date,
            name: av.antivirus?.name,
            active: av?.activated,
            free: !av?.antivirus?.premium,
            version: av?.version || "16.1.14.0",
            date_check_version: av?.date_check_version || "2023-04-14T15:00:00",
          };
        });
        setDataAntiVirus(antiVirus);
      })
      .catch((e) => console.error("Error while retrieving the scanisette", e));

    getLogByScanisetteId(scanisetteId)
    .then((resp) => {
      const logs = resp.data.map((log) => {
        return {
          id: log.id,
          date: log.date,
          message: log.message,
        };
      });
      setDataLog(logs);
    })
    .catch((e) =>
      console.error("Error while retrieving the logs by scanisette ID", e)
    );
  };

  useEffect(() => {
    getData();
    // setDataCom(communication);
    // setDataGame(games);

    getCustomerByScanisetteId(scanisetteId)
      .then((resp) => {
        setCustomerId(resp.data.id);
      })
      .catch((e) =>
        console.error("Error while retrieving the customer by scanisette Id", e)
      );
  }, [scanisetteId]);

  const uploadImageScan = (event) => {};

  useEffect(() => {
    if (customerId) {
      getAllPlaceGroupsByCustomerId(customerId)
        .then((resp) => {
          const sortByLocationOptions = resp.data.map((o) => {
            return {
              value: o.id,
              label: o.name,
            };
          });
          setLocationOptions(sortByLocationOptions);
        })
        .catch((e) =>
          console.error(
            "Error while retrieving the place groups by customer id",
            e
          )
        );
    }
  }, [customerId]);

  const handleSaveScanisette = async () => {
    // We update the place group to change the place group for a specific scanisette
    if (imageScan) {
      let fd = new FormData();
      fd.append("file", imageScan);
      let resp = await uploadFile(fd);
      console.log(resp);
      await updateScanisette(scanisetteId, {
        uri_image_no_comm: resp.data.name,
      });
      setImageScan(null);
      setPreviewImage(null);
      getData();
    }
    if (
      scanisetteLocationInput.trim() === "" &&
      scanisetteLocationSelect &&
      scanissetteName.trim() !== ""
    ) {
      updatePlaceGroupById(scanisetteLocationSelect, {
        scanisettes: [{ id: scanisetteId }],
      })
        .then((resp) => {
          updateScanisette(scanisetteId, {
            custom_name: scanissetteName,
          })
            .then((resp) => getData())
            .catch((err) =>
              console.error("Error while updating the scanisette name", err)
            );
        })
        .catch((e) =>
          console.error(
            "Error while updating the place group for the scanisette",
            e
          )
        );
    } else if (
      scanisetteLocationInput.trim() !== "" &&
      scanissetteName.trim() !== ""
    ) {
      createPlaceGroup({
        name: scanisetteLocationInput,
        note: "",
        customer: { id: customerId },
      })
        .then((resp) => {
          updatePlaceGroupById(resp.data.id, {
            scanisettes: [{ id: scanisetteId }],
          })
            .then((resp) => {
              updateScanisette(scanisetteId, {
                custom_name: scanissetteName,
              })
                .then((resp) => getData())
                .catch((err) =>
                  console.error("Error while updating the scanisette name", err)
                );
            })
            .catch((e) =>
              console.error(
                "Error while updating the place group for the scanisette",
                e
              )
            );
        })
        .catch((e) => console.error("Error while creating the place group", e));
    } else if (
      scanisetteLocationInput.trim() === "" &&
      !scanisetteLocationSelect &&
      scanissetteName.trim() !== ""
    ) {
      updateScanisette(scanisetteId, { custom_name: scanissetteName })
        .then((resp) => getData())
        .catch((err) =>
          console.error("Error while updating the scanisette name", err)
        );
    } else if (
      scanisetteLocationInput.trim() === "" &&
      scanisetteLocationSelect &&
      scanissetteName.trim() === ""
    ) {
      updatePlaceGroupById(scanisetteLocationSelect, {
        scanisettes: [{ id: scanisetteId }],
      })
        .then((resp) => getData())
        .catch((e) =>
          console.error(
            "Error while updating the place group for the scanisette",
            e
          )
        );
    } else if (
      scanisetteLocationInput.trim() !== "" &&
      scanissetteName.trim() === ""
    ) {
      createPlaceGroup({
        name: scanisetteLocationInput,
        note: "",
        customer: { id: customerId },
      })
        .then((resp) => {
          updatePlaceGroupById(resp.data.id, {
            scanisettes: [{ id: scanisetteId }],
          })
            .then((resp) => getData())
            .catch((e) =>
              console.error(
                "Error while updating the place group for the scanisette",
                e
              )
            );
        })
        .catch((e) => console.error("Error while creating the place group", e));
    }
    setScanisetteLocationInput("");
    setScanisetteLocationSelect("");
    setScanissetteName("");
    setManageModal(false);
  };

  const getLastPageScan = () => {
    if (scanisette) {
      return scanisette.scans.length > Math.floor(scanisette.scans.length / 10)
        ? Math.floor(scanisette.scans.length / 10) + 1
        : Math.floor(scanisette.scans.length / 10);
    } else {
      return -1;
    }
  };

  const currentDataLog = useMemo(() => {
      const firstPageIndex = (currentPageLog - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      const totalPageCount = Math.ceil(dataLog.length / PageSize);
      if (totalPageCount < currentPageLog) {
        setCurrentPageLog(1);
      }
      return dataLog.slice(firstPageIndex, lastPageIndex);
    
  }, [dataLog, currentPageLog, PageSize]);

  return (
    <Layout>
      <h1 className="title">
        <img
          src="/assets/goback.svg"
          alt="Go back icon"
          className="go-back"
          onClick={() => navigate(-1)}
        />
        SCANISETTE N°
        {scanisetteId}
      </h1>
      <div className="scanisette">
        <div className="d-flex flex-column align-items-center">
          <div
            className={`card_scanisette ${
              dataScanisette.state === "inactive"
                ? "inactive"
                : dataScanisette.state === "active"
                ? "active"
                : "almost-inactive"
            }`}
          >
            <div className="card_icon">
              <img src={"/assets/scanisette.svg"} alt="Icon card" />
            </div>

            <br />

            <div className="card_info">
              <p>
                <strong>{dataScanisette.name}</strong>
                <span className="hostname">({dataScanisette.hostname})</span>
              </p>
              <p>{dataScanisette.area}</p>
              <span
                className={
                  dataScanisette.state === "active"
                    ? "active"
                    : dataScanisette.state === "inactive"
                    ? "inactive"
                    : "almost-inactive"
                }
              >
                {dataScanisette.state === "active"
                  ? "Active"
                  : dataScanisette.state === "inactive"
                  ? "Inactive"
                  : "Inactive depuis peu"}
              </span>{" "}
              {expired(dataScanisette.expires)
                ? "Licence(s) expirée(s)"
                : expiresSoon(dataScanisette.expires) < 30
                ? "Licence(s) bientôt expirée(s)"
                : "Licence(s) à jour"}
              <hr />
              <p>
                Scans: <span className="info">{dataScanisette.scans}</span>
              </p>
              <p>
                Virus détecté:{" "}
                <span className="info">{dataScanisette.virus}</span>
              </p>
            </div>
          </div>
          <Button
            customClass={"basic_button btn-order"}
            label="Modifier"
            type={"button"}
            onClick={() => setManageModal(true)}
          />
          <Button
            customClass={"basic_button btn-order mt-3"}
            label="Afficher les logs"
            type={"button"}
            onClick={() => setOpenLogModal(true)}
          />
          <Modal3
            title="Modifier"
            show={manageModal}
            onClose={() => setManageModal(false)}
            onConfirm={handleSaveScanisette}
          >
            <div>
              <div>Modifier le nom de la scanisette :</div>
              <Input
                customClass={"input-scanisette-name"}
                type="text"
                value={scanissetteName}
                onChange={(e) => setScanissetteName(e.target.value)}
              />
            </div>
            <div className="mt-5">
              <div>Choisir un lieu :</div>
              <select
                name="location"
                className={`select-menu location`}
                onChange={(e) => setScanisetteLocationSelect(e.target.value)}
              >
                <option value="">--Choisir un lieu--</option>
                {locationOptions.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </select>

              <div className="mt-2">Ou créer un lieu :</div>
              <Input
                customClass={"input-scanisette-location"}
                type="text"
                value={scanisetteLocationInput}
                onChange={(e) => setScanisetteLocationInput(e.target.value)}
              />

              <div className="mt-10">Modifier l'image de scan</div>
              <Input
                customClass={"input-scanisette-location"}
                type="file"
                onChange={(e) => setImageScan(e.target.files[0])}
              />
              {previewImage ? (
                <img className="image_upload mt-2" src={previewImage}></img>
              ) : dataScanisette.uri_image_no_comm ? (
                <img
                  className="image_upload mt-2"
                  src={
                    "https://api.scanisette.k8s.demohc.io/" +
                    "" +
                    dataScanisette.uri_image_no_comm
                  }
                ></img>
              ) : (
                <></>
              )}
            </div>
          </Modal3>
          <Modal4
            title={"Logs de la scanisette"}
            show={openLogModal}
            onClose={() => setOpenLogModal(false)}
          >
            <Table
              theadData={tableHeaderLogs}
              tbodyData={currentDataLog}
              customClass={"table-scanisettes"}
              TableRow={ScanisetteLogScanTableRow}
            />
            <Pagination
              currentPage={currentPageLog}
              totalCount={dataLog.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPageLog(page)}
            />
          </Modal4>
        </div>
        {role === "Admin" ? (
          <div>
            <h3>Licence(s) en cours</h3>
            {dataAntiVirus.map((av) => (
              <FlatCardAV
                key={v4()}
                icon={av.icon}
                id={av.id}
                data={av}
                active={av.active}
                scanisetteId={dataScanisette.id}
                customClass="grid-4"
              />
            ))}
            {/* <hr />
            <h3>Communication</h3>
            {dataCom.map((c) => (
              <FlatCardCom
                key={v4()}
                data={c}
                active={c.active}
                customClass="grid-4-custom"
              />
            ))}
            <hr />
            <h3>Jeux</h3>
            {dataGame.map((g) => (
              <FlatCardGame
                key={v4()}
                data={g}
                active={g.active}
                customClass="grid-4-custom"
              />
            ))} */}
            <hr />
            <h3>Date anniversaire</h3>
            <div className="mb-6">
              {dataScanisette?.anniversary?.split("T")[0]}
            </div>
            <div className="flex justify-between">
              <div className="flex-column flex">
                <h3>IP Publique</h3>
                <div className="">{dataScanisette?.public_ip}</div>
              </div>
              <div className="flex-column flex ml-12">
                <h3>IP Locale</h3>
                <div className="">{dataScanisette?.local_ip}</div>
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <div className="flex-column flex">
                <h3>Modèle</h3>
                <div className="">
                  {dataScanisette?.model ? dataScanisette.model : "N/A"}
                </div>
              </div>
              <div className="flex-column flex ml-12">
                <h3>Manufacturer</h3>
                <div className="">
                  {dataScanisette?.manufacturer
                    ? dataScanisette.manufacturer
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <div className="flex-column flex">
                <h3>Serial</h3>
                <div className="">
                  {dataScanisette?.serial ? dataScanisette.serial : "N/A"}
                </div>
              </div>
              <div className="flex-column flex ml-12"></div>
            </div>
          </div>
        ) : (
          <div>
            <h3>Licence(s) en cours</h3>
            {dataAntiVirus.map((av) => (
              <FlatCardAV6
                key={v4()}
                icon={av.icon}
                id={av.id}
                data={av}
                active={av.active}
                customClass="grid-4"
              />
            ))}
            {/* <hr />
            <h3>Communication</h3>
            {dataCom.map((c) => (
              <FlatCardCom2
                key={v4()}
                data={c}
                active={c.active}
                customClass="grid-4-custom"
              />
            ))}
            <hr />
            <h3>Jeux</h3>
            {dataGame.map((g) => (
              <FlatCardGame3
                key={v4()}
                data={g}
                active={g.active}
                customClass="grid-4-custom"
              />
            ))} */}
            <hr />
            <h3>Date anniversaire</h3>
            <div className="mb-6">
              {dataScanisette?.anniversary?.split("T")[0]}
            </div>
            <div className="flex justify-between">
              <div className="flex-column flex">
                <h3>IP Publique</h3>
                <div className="">{dataScanisette?.public_ip}</div>
              </div>
              <div className="flex-column flex ml-12">
                <h3>IP Locale</h3>
                <div className="">{dataScanisette?.local_ip}</div>
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <div className="flex-column flex">
                <h3>Modèle</h3>
                <div className="">
                  {dataScanisette?.model ? dataScanisette.model : "N/A"}
                </div>
              </div>
              <div className="flex-column flex ml-12">
                <h3>Manufacturer</h3>
                <div className="">
                  {dataScanisette?.manufacturer
                    ? dataScanisette.manufacturer
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <div className="flex-column flex">
                <h3>Serial</h3>
                <div className="">
                  {dataScanisette?.serial ? dataScanisette.serial : "N/A"}
                </div>
              </div>
              <div className="flex-column flex ml-12"></div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-column list_virus mt-10">
        {scanisette ? (
          scanisette.scans
            .sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);

              return dateB - dateA;
            })
            .slice(0 + 10 * (compteurPage - 1), 10 * compteurPage)
            .map((s, idx) => (
              <details className="scan_details">
                <summary className="scan_row">
                  <span className="ml-10">
                    <span className="tag_scan">ANTIVIRUS</span> {s.antivirus}
                  </span>
                  <span>
                    <span className="tag_scan">DATE</span>{" "}
                    {formatDate(new Date(s.date), "yyyy-mm-dd HH:MM:SS")}
                  </span>
                  <span>
                    <span className="tag_scan">FICHIERS</span> {s.nb_files}
                  </span>
                  <span className="mr-10">
                    <span
                      className={`tag_scan ${
                        s.nb_virus > 0 ? "b_danger" : "b_ok"
                      }`}
                    >
                      VIRUS
                    </span>{" "}
                    {s.nb_virus}
                  </span>
                </summary>
                <div className="detail_scan">
                  {s.virus.length > 0 ? (
                    s.virus.map((v, index) => (
                      <div className="virus_detail">
                        <span className="ml-10">
                          <span className="tag_scan">VIRUS</span> {v.name}
                        </span>
                        <span className="mr-10">
                          <span className="tag_scan">FICHIER</span> {v.file}
                        </span>
                      </div>
                    ))
                  ) : (
                    <p className="no_virus">Aucun virus détecté</p>
                  )}
                </div>
              </details>
            ))
        ) : (
          <></>
        )}
      </div>
      <div className="mt-10 pagination">
        <span
          onClick={() =>
            compteurPage > 1 ? setCompteurPage(compteurPage - 1) : {}
          }
        >
          page précédente
        </span>
        <span
          onClick={() =>
            compteurPage < getLastPageScan()
              ? setCompteurPage(compteurPage + 1)
              : {}
          }
        >
          page suivante
        </span>
      </div>
    </Layout>
  );
}

export default Scanisette;
