import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlatCardCustomer from "../components/FlatCardCustomer.generic";
import Pagination from "../components/Pagination.generic";
import Select from "../components/Select.generic";
import Layout from "../layouts/Layout";

const dataCustomer = [
  {
    id: 1,
    name: "Nom client 1",
    icon: "https://via.placeholder.com/150x75",
    number: 120,
    scans: 1254,
    virus: 54,
  },
  {
    id: 2,
    name: "Nom client 2",
    icon: "https://via.placeholder.com/150x75",
    number: 24,
    scans: 1254,
    virus: 54,
  },
  {
    id: 3,
    name: "Nom client 3",
    icon: "https://via.placeholder.com/150x75",
    number: 5,
    scans: 1254,
    virus: 54,
  },
  {
    id: 4,
    name: "Nom client 4",
    icon: "https://via.placeholder.com/150x75",
    number: 8,
    scans: 1254,
    virus: 54,
  },
  {
    id: 5,
    name: "Nom client 5",
    icon: "https://via.placeholder.com/150x75",
    number: 52,
    scans: 1254,
    virus: 54,
  },
  {
    id: 6,
    name: "Nom client 6",
    icon: "https://via.placeholder.com/150x75",
    number: 3,
    scans: 1254,
    virus: 54,
  },
  {
    id: 7,
    name: "Nom client 7",
    icon: "https://via.placeholder.com/150x75",
    number: 34,
    scans: 1254,
    virus: 54,
  },
  {
    id: 8,
    name: "Nom client 8",
    icon: "https://via.placeholder.com/150x75",
    number: 64,
    scans: 1254,
    virus: 54,
  },
  {
    id: 9,
    name: "Nom client 9",
    icon: "https://via.placeholder.com/150x75",
    number: 32,
    scans: 1254,
    virus: 54,
  },
  {
    id: 10,
    name: "Nom client 10",
    icon: "https://via.placeholder.com/150x75",
    number: 58,
    scans: 1254,
    virus: 54,
  },
  {
    id: 11,
    name: "Nom client 11",
    icon: "https://via.placeholder.com/150x75",
    number: 2,
    scans: 1254,
    virus: 54,
  },
  {
    id: 12,
    name: "Nom client 12",
    icon: "https://via.placeholder.com/150x75",
    number: 44,
    scans: 1254,
    virus: 54,
  },
  {
    id: 13,
    name: "Nom client 13",
    icon: "https://via.placeholder.com/150x75",
    number: 84,
    scans: 1254,
    virus: 54,
  },
];

const sortByOptions = [
  {
    value: "",
    label: "Trier par",
  },
  {
    value: "name",
    label: "Nom",
  },
  {
    value: "number",
    label: "Nb de scanisette",
  },
];

const nbrPageOptions = [
  {
    value: 12,
    label: 12,
  },
  {
    value: 24,
    label: 24,
  },
  {
    value: 48,
    label: 48,
  },
];

function DashboardAdmin() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [sortType, setSortType] = useState("name");
  const [sortPage, setSortPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  let PageSize = +sortPage;

  useEffect(() => {
    setData(dataCustomer);
  }, []);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(data.length / PageSize);
    if (totalPageCount < currentPage) {
      setCurrentPage(1);
    }
    return data
      .sort((a, b) =>
        a[sortType]?.toString().localeCompare(b[sortType]?.toString(), "fr", {
          numeric: true,
        })
      )
      .slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, PageSize, sortType]);

  const handleClick = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  return (
    <Layout>
      <h1 className="title-dashboard">FLOTTE CLIENTS</h1>
      <div className="display-menu">
        <Select
          name="sortBy"
          options={sortByOptions}
          handleChange={setSortType}
        />

        <div className="display-menu-right">
          <span>Clients par page :</span>
          <Select
            name="nbrPage"
            options={nbrPageOptions}
            handleChange={setSortPage}
          />
        </div>
      </div>
      <div className="flex-column">
        {currentData.map((d) => (
          <FlatCardCustomer
            key={d.id}
            data={d}
            customClass={"grid-6-custom"}
            onClick={(e) => handleClick(e.currentTarget.id)}
          />
        ))}
        <Pagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </Layout>
  );
}

export default DashboardAdmin;
