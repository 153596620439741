import React, { useContext, useEffect, useMemo, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "../components/Button.generic";
import Input from "../components/Input.generic";
import Modal from "../components/Modal.generic";
import Pagination from "../components/Pagination.generic";
import Table from "../components/Table.generic";
import { AuthContext } from "../contexts/AuthContext";
import Layout from "../layouts/Layout";
import {
  deleteMultipleUsers,
  getUsersByCustomerId,
} from "../services/User.services";
import TabContent from "../components/TabContent.generic";
import TabNavItem from "../components/TabNavItem.generic";
import SettingsUserTableRow from "./Table/SettingsUserTableRow";
import {
  getCustomerById,
  updateCustomerById,
} from "../services/Customer.services";
import { getLogByCustomerId } from "../services/Log.services";
import SettingsLogTableRow from "./Table/SettingsLogTableRow";

const tableHeaderUser = [" ", "Nom", "Prénom", "E-mail", " "];

const tableHeaderLogs = ["Date", "Message"];

function Settings() {
  const [dataUser, setDataUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("tab1");
  const [virusDetection, setVirusDetection] = useState("");
  const [virusSuspicion, setVirusSuspicion] = useState("");
  const [dataLog, setDataLog] = useState([]);
  const [currentPageLog, setCurrentPageLog] = useState(1);
  // const navigate = useNavigate();

  const customerId = user?.customers[0]?.id;

  let PageSize = +12;

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    if (customerId) {
      getUsersByCustomerId(customerId)
        .then((resp) => {
          const users = resp.data.map((user) => {
            return {
              id: user?.id,
              lastname: user?.name,
              firstname: user?.firstname,
              email: user?.email,
              // role: user.role.name,
              // role_id: user.role.id, Necessary to update the role in SettingsUserTableRow
            };
          });
          setDataUser(users);
        })
        .catch((e) =>
          console.error("Error while retrieving the users by customer ID", e)
        );

      getCustomerById(customerId)
        .then((resp) => {
          setVirusDetection(
            resp.data.message_virus && resp.data.message_virus !== " "
              ? resp.data.message_virus
              : ""
          );
          setVirusSuspicion(
            resp.data.message_suspicion && resp.data.message_suspicion !== " "
              ? resp.data.message_suspicion
              : ""
          );
        })
        .catch((e) => console.error("Error while retrieving the customer", e));

      getLogByCustomerId(customerId)
        .then((resp) => {
          const logs = resp.data.map((log) => {
            return {
              id: log.id,
              date: log.date,
              message: log.message,
            };
          });
          setDataLog(logs);
        })
        .catch((e) =>
          console.error("Error while retrieving the logs by customer ID", e)
        );
    }
  };

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    // The following lines are to prevent the following behavior : if the user is on page 2 and start to search for a user the results won't be shown as they are displayed on page 1 but the current page is 2
    const totalPageCount = Math.ceil(
      dataUser.filter((row) => {
        if (query === "") {
          return row;
        } else if (row.lastname.toLowerCase().includes(query.toLowerCase())) {
          return row;
        } else {
          return false;
        }
      }).length / PageSize
    );
    if (totalPageCount < currentPage) {
      setCurrentPage(1);
    }
    // End
    setCurrentData(
      dataUser
        .filter((row) => {
          if (query === "") {
            return row;
          } else if (row.lastname.toLowerCase().includes(query.toLowerCase())) {
            return row;
          } else {
            return false;
          }
        })
        .slice(firstPageIndex, lastPageIndex)
    );
  }, [dataUser, currentPage, PageSize, query]);

  // const handleNewUser = () => {
  //   navigate("/add/user");
  // };

  const currentDataLog = useMemo(() => {
    const firstPageIndex = (currentPageLog - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(dataLog.length / PageSize);
    if (totalPageCount < currentPageLog) {
      setCurrentPageLog(1);
    }
    return dataLog.slice(firstPageIndex, lastPageIndex);
  }, [dataLog, currentPageLog, PageSize]);

  const onDelete = () => {
    if (selectedUser.length > 0) {
      deleteMultipleUsers({ object_ids: selectedUser.map((s) => +s) })
        .then(() => {
          getDatas();
          setConfirmDeletePopup(false);
          setSelectedUser([]);
        })
        .catch((e) => console.error("Error while deleting multiple users", e));
    }
  };

  const handleSave = () => {
    if (customerId) {
      if (virusDetection.trim() !== "" && virusSuspicion.trim() !== "") {
        updateCustomerById(customerId, {
          message_virus: virusDetection,
          message_suspicion: virusSuspicion,
        }).catch((e) => console.error("Error while updating the customer", e));
      }
      if (virusDetection.trim() !== "" && virusSuspicion.trim() === "") {
        updateCustomerById(customerId, {
          message_virus: virusDetection,
        }).catch((e) => console.error("Error while updating the customer", e));
      }
      if (virusSuspicion.trim() !== "" && virusDetection.trim() === "") {
        updateCustomerById(customerId, {
          message_suspicion: virusSuspicion,
        }).catch((e) => console.error("Error while updating the customer", e));
      }
    }
  };

  const handleCancel = () => {
    if (customerId) {
      updateCustomerById(customerId, {
        message_virus: " ",
        message_suspicion: " ",
      })
        .then((resp) => getDatas())
        .catch((e) => console.error("Error while resetting the customer", e));
    }
  };

  return (
    <Layout>
      <h1 className="title">Paramètres</h1>
      <ul className="nav-items">
        <TabNavItem
          title="Utilisateurs"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Notifications"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Logs"
          id="tab3"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <TabContent id="tab1" activeTab={activeTab}>
        <div className="display-menu-customers">
          <Input
            customClass={"search-bar"}
            type="text"
            placeholder={"Rechercher un nom"}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="display-menu-right">
            <Button
              customClass={
                selectedUser.length !== 0
                  ? "basic_button btn-order"
                  : "basic_button btn-order disabled"
              }
              label="Supprimer un utilisateur"
              type={"button"}
              onClick={() => setConfirmDeletePopup(true)}
              disabled={selectedUser.length !== 0 ? false : true}
            />
            {/* <Button
            customClass={"basic_button btn-order"}
            label="Ajouter un utilisateur"
            type={"button"}
            onClick={handleNewUser}
          /> */}
          </div>
        </div>
        <Table
          theadData={tableHeaderUser}
          tbodyData={currentData}
          customClass={"table-scanisettes"}
          TableRow={SettingsUserTableRow}
          selectedRow={selectedUser}
          setSelectedRow={setSelectedUser}
          getDatas={getDatas}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={
            dataUser.filter((row) => {
              if (query === "") {
                return row;
              } else if (
                row.lastname.toLowerCase().includes(query.toLowerCase())
              ) {
                return row;
              } else {
                return false;
              }
            }).length
          }
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
        <Modal
          title="Supprimer"
          show={confirmDeletePopup}
          onClose={() => setConfirmDeletePopup(false)}
          onConfirm={onDelete}
        >
          Êtes-vous sûr de vouloir supprimer le(s) utilisateur(s) sélectionné(s)
          ?
        </Modal>
      </TabContent>

      <TabContent id="tab2" activeTab={activeTab}>
        <div className="d-flex f-column align-items-center g-5 pr-8 pl-8">
          <div className="d-flex f-column">
            <h2>Détection de Virus</h2>
            <label htmlFor="detection-virus">
              Veuillez renseigner le message à afficher en cas de{" "}
              <strong>détection</strong> de virus :
            </label>

            <textarea
              id="detection-virus"
              name="detection-virus"
              rows="5"
              cols="35"
              placeholder="Virus détecté. Veuillez contacter l'administrateur réseau."
              onChange={(e) => setVirusDetection(e.target.value)}
              value={virusDetection}
            ></textarea>
          </div>

          <div className="d-flex f-column">
            <h2>Suspicion de Virus</h2>
            <label htmlFor="suspicion-virus">
              Veuillez renseigner le message à afficher en cas de{" "}
              <strong>suspicion</strong> de virus :
            </label>

            <textarea
              id="suspicion-virus"
              name="suspicion-virus"
              rows="5"
              cols="35"
              placeholder="Suspicion de virus. Veuillez contacter l'administrateur réseau."
              onChange={(e) => setVirusSuspicion(e.target.value)}
              value={virusSuspicion}
            ></textarea>
          </div>

          <div className="flex g-2">
            <Button
              label={"Enregistrer"}
              customClass="basic_button btn-order"
              onClick={handleSave}
            />
            <Button
              label={"Annuler"}
              customClass="basic_button btn-order"
              onClick={handleCancel}
            />
          </div>
        </div>
      </TabContent>
      <TabContent id="tab3" activeTab={activeTab}>
        <Table
          theadData={tableHeaderLogs}
          tbodyData={currentDataLog}
          customClass={"table-scanisettes"}
          TableRow={SettingsLogTableRow}
        />
        <Pagination
          currentPage={currentPageLog}
          totalCount={dataLog.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPageLog(page)}
        />
      </TabContent>
    </Layout>
  );
}

export default Settings;
