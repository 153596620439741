import React from "react";

function InputWithLabel({
  customClass,
  customClassLabel,
  id,
  type,
  value,
  onChange,
  placeholder,
  label,
  min,
  max,
  register,
}) {
  return (
    <>
      <label htmlFor={id} className={customClassLabel}>
        {label}
      </label>
      <input
        onChange={onChange}
        value={value}
        className={customClass}
        id={id}
        type={type}
        placeholder={placeholder}
        min={min}
        max={max}
        {...(register ? { ...register(id) } : null)}
      />
    </>
  );
}

export default InputWithLabel;
