import React from "react";

import "./switch.style.scss";

const Switch = ({ isOn, handleToggle, onColor, id }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="switch-checkbox"
        id={`switch-${id}`}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className="switch-label"
        htmlFor={`switch-${id}`}
      >
        <span className={`switch-button`} />
      </label>
    </>
  );
};

export default Switch;
