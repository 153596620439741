import API from './index';

//export const updateTicketById = (id, data) => API.put(`/ticket/${id}`, data);  Not used at now

//export const getAllTickets = () => API.get('/ticket');                         Not used at now

export const getTicketsByCustomerId = (id) => API.get(`/ticket/customer/${id}`);

export const getTicketById = (id) => API.get(`/ticket/${id}`);

export const createTicket = (data) => API.post(`/ticket`, data);

export const deleteTicketById = (id) => API.delete(`/ticket/${id}`);

export const addReply = (data) => API.post(`/ticket/${data.id_ticket}/reply`, { content: data.content });
