import React, { useState } from "react";

import Menu from "../components/Menu.generic";
import Header from "../components/Header.generic";

function Layout({ children }) {
  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="page">
      <div className="left-side column">
        <div className="left-header">
          <button onClick={toggleOpen}>
            <img
              src={open ? "/assets/arrow-left.svg" : "/assets/arrow-right.svg"}
              alt="Arrow"
            />
          </button>
        </div>
        <div className={open ? "side-menu" : "side-menu closed"}>
          <Menu open={open} />
        </div>
      </div>
      <div className="right-side column">
        <div className="right-header">
          <Header />
        </div>

        <div className="page_content">{children}</div>
      </div>
    </div>
  );
}

export default Layout;
