import React from "react";
import { useNavigate } from "react-router-dom";

function ScanisettesTableRow({ data }) {
  const navigate = useNavigate();

  const handleClick = (scanisetteId) => {
    navigate(`/scanisettes/${scanisetteId}`);
  };

  return (
    <tr id={data.id} onClick={(e) => handleClick(e.currentTarget.id)}>
      <td className="id">#{data.id}</td>
      <td>
        <span
          className={`span-status ${
            data.state === "active"
              ? "active"
              : data.state === "inactive"
              ? "inactive"
              : "almost-inactive"
          }`}
        >
          {data.state === "active"
            ? "Active"
            : data.state === "inactive"
            ? "Inactive"
            : "Inactive depuis peu"}
        </span>
      </td>
      <td>{data.name}</td>
      <td>{data.area}</td>
      <td>{data.licence}</td>
      <td>{data.scans}</td>
      <td>{data.scans_month}</td>
      <td>{data.virus}</td>
      <td>{data?.anniversary?.split("T")[0]}</td>
      <td>
        <img src="/assets/arrow.svg" alt="Arrow icon" />
      </td>
    </tr>
  );
}

export default ScanisettesTableRow;
