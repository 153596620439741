import React from "react";
import { useNavigate } from "react-router-dom";

function FlatCardCom3({ data, customClass, selectedCom, setSelectedCom }) {
  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    // If the selected row are not yet in the array add it, else remove it from the array
    let newArray = [...selectedCom, e.target.value];
    if (selectedCom.includes(e.target.value)) {
      newArray = newArray.filter((d) => d !== e.target.value);
    }
    setSelectedCom(newArray);
  };

  return (
    <div id={data.id} className={`flatCard ${customClass}`}>
      <input
        type="checkbox"
        id={data.id}
        value={`${data.id}-${data.name}`}
        onChange={handleCheckboxChange}
      />

      <div className="card_icon">
        <div className="icon"></div>
      </div>

      <div className="bold">{data.name}</div>

      <div className="card-column">
        <span>Date de création</span>
        <span>{data.created}</span>
      </div>

      <div className="card-column">
        <span>
          Active sur <span className="blue bold">{data.nb_scanisette}</span>
        </span>
        <span>scanisettes</span>
      </div>
      <span className="edit">Dupliquer</span>
      <div onClick={() => navigate(`/communication/${data.id}`)}>
        <img src="/assets/arrow.svg" alt="Flèche droite" />
      </div>
    </div>
  );
}

export default FlatCardCom3;
