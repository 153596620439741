import React from "react";
import { expired, expiresSoon } from "../utils/func";

function FlatCardScan({ id, icon, label, data, customClass, onClick }) {
  return (
    <div id={id} onClick={onClick} className={`flatCard_${customClass}`}>
      <div className="card_icon">
        <img src={icon} alt="Scanisette icon" />
      </div>
      <div className="card_info">
        <span
          className={
            label === "Active"
              ? "active"
              : label === "Inactive"
              ? "inactive"
              : "almost-inactive"
          }
        >
          {label}
        </span>
        <br />
        {expired(data.expires)
          ? "Licence(s) expirée(s)"
          : expiresSoon(data.expires) < 30
          ? "Licence(s) bientôt expirée(s)"
          : "Licence(s) à jour"}
      </div>
      <div>
        <h3>{data.name} <span className="hostname">({data.hostname})</span></h3>
        <p>{data.area}</p>
      </div>
      <p>
        <span className="info">{data.scans}</span>
        <br />
        scans
      </p>
      <p>
        <span className="info">{data.virus}</span>
        <br />
        virus détectés
      </p>
      <span className="more">+</span>
    </div>
  );
}

export default FlatCardScan;
