import React from "react";
import { v4 } from "uuid";
import { usePagination, DOTS } from "../hooks/usePagination";

import "./pagination.style.scss";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={
        className ? `pagination-container ${className}` : "pagination-container"
      }
    >
      <li
        className={
          currentPage === 1 ? "pagination-item disabled" : "pagination-item"
        }
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li key={v4()} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={v4()}
            className={
              pageNumber === currentPage
                ? "pagination-item selected"
                : "pagination-item"
            }
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={
          currentPage === lastPage
            ? "pagination-item disabled"
            : "pagination-item"
        }
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
