import React from "react";

function Input({
  customClass,
  id,
  type,
  value,
  onChange,
  placeholder,
  min,
  max,
  required,
}) {
  return (
    <input
      onChange={onChange}
      value={value}
      className={customClass}
      id={id}
      type={type}
      required={required}
      placeholder={placeholder}
      min={min}
      max={max}
    />
  );
}

export default Input;
