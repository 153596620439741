import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputWithLabel from "../components/InputWithLabel.generic";
import Button from "../components/Button.generic";
import { getAllCustomersGlobal } from "../services/Customer.services";
import { createUser } from "../services/User.services";
import { useNavigate } from "react-router-dom";
import { getDeployedScanVirus } from "../services/Stats.services";
import { getAllRolesGlobal } from "../services/Role.services";

const schema = yup
  .object({
    firstName: yup.string().required("Ce champ est obligatoire"),
    lastName: yup.string().required("Ce champ est obligatoire"),
    email: yup
      .string()
      .email("Un @ est nécessaire")
      .required("Ce champ est obligatoire"),
    password: yup
      .string()
      .required("Ce champ est obigatoire")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$/,
        "Doit contenir entre 6 et 20 caractères, une letre, un chriffre, et un caractère spécial"
      ),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passe doivent être identiques"
      ),
    phone: yup.string().required("Ce champ est obligatoire"),
    customer: yup.string().required("Ce champ est obigatoire"),
  })
  .required();

function Register() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [customers, setCustomers] = useState([
    {
      value: "",
      label: "Sélectionner un client",
    },
  ]);
  const [role, setRole] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      customer: "",
      phone: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getDeployedScanVirus()
      .then((resp) => {
        const dataScanisette = {
          scans: resp?.data?.scan,
          virus: resp?.data?.virus,
          scanisettes: resp?.data?.deployed,
        };
        setData(dataScanisette);
      })
      .catch((e) =>
        console.error(
          "Error while retrieving the deployed scnanisette, the number of virus detected and the number of scans realized",
          e
        )
      );
    getAllCustomersGlobal()
      .then((resp) => {
        let customersArr = resp.data.map((r) => {
          return {
            value: r.id,
            label: r.name,
          };
        });
        setCustomers(customersArr);
      })
      .catch((e) =>
        console.error("Error while retrieving the customers globally", e)
      );

    getAllRolesGlobal()
      .then((resp) => {
        let roleArr = resp.data.filter((r) => r.name === "Admin");
        setRole(roleArr);
      })
      .catch((e) => console.error("Error while retrieving the roles", e));
  }, []);

  const onSubmit = (formData) => {
    createUser({
      email: formData.email,
      name: formData.lastName,
      firstname: formData.firstName,
      phone_number: formData.phone,
      password: formData.password,
      login: `${formData.firstName
        .charAt(0)
        .toLowerCase()}${formData.lastName.toLowerCase()}`,
      customer: [{ id: formData.customer }],
      role: role[0],
    })
      .then(() => navigate("/"))
      .catch((e) => console.error("Error while creating a user", e));
  };

  return (
    <div className="login">
      <div className="data-section">
        <div className="card">
          <img src="/assets/scanisette2.svg" alt="Icon usb key" />
          <span>
            <strong>{data?.scanisettes}</strong>
          </span>
          <span>scanisettes déployées</span>
        </div>

        <div className="card">
          <img src="/assets/scan.svg" alt="Icon scan" />
          <span>
            <strong>{data?.scans}</strong>
          </span>
          <span>scans réalisés</span>
        </div>

        <div className="card">
          <img src="/assets/virus.svg" alt="Icon virus" />
          <span>
            <strong>{data?.virus}</strong>
          </span>
          <span>virus détectés</span>
        </div>
      </div>
      <form className="register-section" onSubmit={handleSubmit(onSubmit)}>
        <img
          src={"/assets/logo.svg"}
          alt="Logo scanisette"
          className="login-logo"
        />
        <h1 className="title_form mt-8">Créer un compte</h1>
        <div className="input-container">
          <InputWithLabel
            type="text"
            label="Prénom"
            placeholder="Votre prénom"
            customClass="basic_input input-width"
            customClassLabel="input_label mt-6"
            id="firstName"
            register={register}
          />
          {errors.firstName && (
            <span role="alert">{errors.firstName?.message}</span>
          )}
        </div>

        <div className="input-container">
          <InputWithLabel
            type="text"
            label="Nom"
            placeholder="Votre nom"
            customClass="basic_input input-width"
            customClassLabel="input_label mt-6"
            id="lastName"
            register={register}
          />
          {errors.lastName && (
            <span role="alert">{errors.lastName?.message}</span>
          )}
        </div>

        <div className="input-container">
          <InputWithLabel
            type="email"
            label="Email"
            placeholder="Votre email"
            customClass="basic_input input-width"
            customClassLabel="input_label mt-6"
            id="email"
            register={register}
          />
          {errors.email && <span role="alert">{errors.email?.message}</span>}
        </div>

        <div className="input-container">
          <InputWithLabel
            type="password"
            label="Mot de passe"
            placeholder=""
            customClass="basic_input input-width"
            customClassLabel="input_label mt-6"
            id="password"
            register={register}
          />
          {errors.password && (
            <span role="alert">{errors.password?.message}</span>
          )}
        </div>

        <div className="input-container">
          <InputWithLabel
            type="password"
            label="Confirmation du mot de passe"
            placeholder=""
            customClass="basic_input input-width"
            customClassLabel="input_label mt-6"
            id="passwordConfirmation"
            register={register}
          />
          {errors.passwordConfirmation && (
            <span role="alert">{errors.passwordConfirmation?.message}</span>
          )}
        </div>

        <div className="input-container">
          <InputWithLabel
            type="text"
            label="Téléphone"
            placeholder="Votre numéro de téléphone"
            customClass="basic_input input-width"
            customClassLabel="input_label mt-6"
            id="phone"
            register={register}
          />
          {errors.phone && <span role="alert">{errors.phone?.message}</span>}
        </div>

        <div className="input-container">
          <span className="mt-6">Client</span>
          <select
            name="customer"
            className={`select-menu`}
            {...register("customer")}
          >
            <option value="">Sélectionner un client</option>
            {customers.map((c) => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
          </select>
          {errors.customer && (
            <span role="alert">{errors.customer?.message}</span>
          )}
        </div>

        <Button
          label="Créer le compte"
          customClass="basic_button btn-order mt-8"
          type="submit"
        />
      </form>
    </div>
  );
}

export default Register;
