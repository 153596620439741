import React from "react";

function TabNavItem({ id, title, activeTab, setActiveTab }) {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li
      onClick={handleClick}
      className={activeTab === id ? "nav-item active" : "nav-item"}
    >
      {title}
    </li>
  );
}

export default TabNavItem;
